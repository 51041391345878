<ng-container *ngIf="isLoading">
  <tix-app-loader></tix-app-loader>
</ng-container>

<ng-container *ngIf="!isLoading">
  <tix-continuous-release-checker
    [companyId]="companyId"
    (redirect)="checkReleaseAndRedirect()"
  >
  </tix-continuous-release-checker>

  <tix-public-header
    [venueId]="venueId"
    [venueInfo]="venueInfo$ | async"
  ></tix-public-header>
  <div class="tix-layout-content">
    <div class="tix-content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
  <tix-footer></tix-footer>
</ng-container>
