import { Injectable } from '@angular/core';
import {
  Auth,
  AuthProvider,
  authState,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  User,
  UserCredential
} from '@angular/fire/auth';
import { UserCredentials } from '@tix/shared/models';
import { from, Observable, of } from 'rxjs';

@Injectable()
export class TixAuthService {
  constructor(private auth: Auth) {}

  loadUser(): Observable<User | null> {
    return authState(this.auth);
  }

  emailPasswordLogin(credentials: UserCredentials): Observable<UserCredential> {
    return from(
      signInWithEmailAndPassword(
        this.auth,
        credentials.email,
        credentials.password
      )
    );
  }

  registerByEmail(createUser: UserCredentials): Observable<UserCredential> {
    return from(
      createUserWithEmailAndPassword(
        this.auth,
        createUser.email,
        createUser.password
      )
    );
  }

  logout(): Observable<any> {
    return from(signOut(this.auth));
  }

  signInMethod(method: 'google' | 'fb' | 'apple' | 'twitter') {
    let provider: AuthProvider = {} as AuthProvider;
    switch (method) {
      case 'google':
        provider = new GoogleAuthProvider();
        break;
      case 'fb':
        provider = new FacebookAuthProvider();
        break;
      case 'twitter':
        provider = new TwitterAuthProvider();
        break;
      case 'apple':
      default:
        break;
    }
    return from(signInWithPopup(this.auth, provider));
  }

  signInWithToken(token: string) {
    return from(signInWithCustomToken(this.auth, token));
  }

  getToken(): Observable<string | null> {
    if (!this.auth.currentUser) return of(null);

    return from(this.auth.currentUser!.getIdToken());
  }
}
