<ng-container *ngIf="shouldRedirect$ | async">
  <div class="fixed top-8 right-8 left-8 z-50">
    <div
      class="ml-auto bg-white rounded-md border border-gray-200 shadow-md p-6 w-full sm:w-96 md:w-[600px]"
    >
      <h2 class="text-md font-bold mb-2">Maintenance Update!</h2>

      <p class="mb-4 text-sm">
        We’ve just finished some important updates to improve your experience.
        The website is now fully operational with new features and enhancements.
        <br />
        The page will be automatically refreshed in
        {{ timerRemainingTimeFormatted$ | async }}. <br />
        Make sure to save your work.
      </p>

      <mat-progress-bar
        [value]="timerRemainingPercentage$ | async"
      ></mat-progress-bar>
    </div>
  </div>
</ng-container>
