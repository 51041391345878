export const environment = {
  production: true,
  logrocketAppID: 'a0f9xx/evold',
  testStripeKey:
    'pk_test_51Jyc65D7DXV9TT5hYp0Vfi9XFxYhfZoStr0mofpX9Ru2fj2vii6ZGoI2qSgguCbYUABHO4g3YLwDf3ocmD0phnwl00OruQzZYE',
  liveStripeKey:
    'pk_live_51Jyc65D7DXV9TT5hoNf6L0BBlvIdyaizdMOCngr4Fqc8tCST7TgAsZ5k9bKGpbHHzurVBDqsKKz83GPLxEREwhsE00h6wnccCc',
  backendUrl: 'https://immense-lunatix.hasura.app/v1/graphql',
  wss: 'wss://immense-lunatix.hasura.app/v1/graphql',
  hasuraSecret:
    'L3er5wbpOzmkJ20BpJFuL4l5C61upnhsLA1Rh2Obj2ljqpJpQv06NnTQAavTsQef',
  customApiUrl: 'https://asylumtix-be.herokuapp.com',

  releaseApiUrl: 'https://webserver-281902514683.us-central1.run.app',
  disableDynamicReleaseRedirect: false,
  disableLoggingOverride: false
};
