import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export class TimerBaseComonent {
  private timer = new BehaviorSubject(Infinity);
  readonly timer$ = this.timer.asObservable();

  readonly timerExpired$ = this.timer$.pipe(map(timer => timer <= 0));

  private timerInterval?: ReturnType<typeof setInterval>;

  get timerActive() {
    return !!this.timerInterval;
  }

  resetTimer(durationSecs: number) {
    if (this.timerInterval) clearInterval(this.timerInterval);
    this.timerInterval = undefined;

    this.timer.next(durationSecs);
  }

  startTimer() {
    if (this.timerInterval) clearInterval(this.timerInterval);
    this.timerInterval = undefined;

    this.timerInterval = setInterval(() => {
      this.timer.next(this.timer.value - 1);

      if (this.timer.value <= 0) {
        this.timer.next(0);
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if (this.timerInterval) clearInterval(this.timerInterval);
  }
}
