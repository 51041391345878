import { Action, createReducer, on, State } from '@ngrx/store';
import {
  addLog,
  clearLogs,
  addNetworkCall,
  clearNetworkCalls
} from './logs.actions';
import { initialLogsState, LogsState } from './log.state';

export const logsReducer = createReducer(
  initialLogsState,
  on(addLog, (state, { log }) => {
    return {
      ...state,
      logs: [...state.logs, log]
    };
  }),
  on(addNetworkCall, (state, { networkCall }) => ({
    ...state,
    networkCalls: [...state.networkCalls, networkCall]
  }))
);

export const LOGS_FEATURE_KEY = 'logs';
export function reducer(state: LogsState | undefined, action: Action) {
  return logsReducer(state, action);
}
