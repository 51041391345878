import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommunicationsService } from 'libs/communications/components/src/lib/services/communications.service';
import { TixConfirmDialogService } from '@tix/shared/ui/components';
import { TixBusinessCodes, TixEmailContent } from '@tix/data-access';

type template = {
  formGroup: FormGroup;
  templateNames: Partial<TixEmailContent>[];
  templateTypes?: Partial<TixBusinessCodes>[];
};

@Component({
  selector: 'tix-communications-edit',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class TixCommunicationsEditComponent implements OnInit {
  templateTypes: Partial<TixBusinessCodes>[] = [];
  templateNames: Partial<TixEmailContent>[] = [];
  existingTemplates: any[] = [];
  @Output() setEmailTemplates = new EventEmitter<template[]>();
  @Output() enableBtn = new EventEmitter<boolean>();
  @Input() id: string | null;
  @Input() type: string | null;
  companyId: string;
  templateForm: template;
  templateGroupArrays: template[] = [];

  @Input() editPermission = true;

  constructor(
    private communicationService: CommunicationsService,
    private route: ActivatedRoute,
    private confirmDialogService: TixConfirmDialogService
  ) {}

  async confirmGroupFormDelete(index: number, group: FormGroup) {
    const confirmText = `Are you sure you want to delete this email template?`;
    const result = await this.confirmDialogService.promptConfirm({
      confirmText,
      title: 'Confirm Delete',
      icon: 'exclamation',
      titleTheme: 'danger',
      cancelButton: {
        theme: 'danger'
      },
      confirmButton: {
        theme: 'danger'
      }
    });

    if (result) {
      this.templateGroupArrays.splice(index, 1);
      this.existingTemplates.splice(group.controls['templateType'].value, 1);
      if (this.templateGroupArrays[0]) {
        this.templateGroupArrays[0].formGroup.markAsDirty();
      }
      this.enableBtn.emit(true);
      this.setEmailTemplates.emit(this.templateGroupArrays);
    }
  }

  onTemplateTypeChange(value: string | undefined, template: template) {
    if (!this.existingTemplates.includes(value)) {
      this.existingTemplates.push(value);
    }

    this.communicationService
      .getTemplatesByBusinessCode(
        template.formGroup.controls['templateType'].value,
        this.companyId
      )
      .subscribe(data => {
        template.templateNames = data.data.EmailContentSelect;
      });
  }
  onTemplateNameChange(value: string, template: template) {
    template?.formGroup?.controls['templateName'].setValue(value);
    this.setEmailTemplates.emit(this.templateGroupArrays);
    this.enableBtn.emit(true);
  }

  ngOnInit(): void {
    // if (!this.editPermission) this.templateForm.formGroup.disable()

    if (this.id != '' && this.type != '') {
      this.communicationService
        .getCommunicationEmailByIdAndType(this.id || '', this.type || '')
        .subscribe(data => {
          data.map((item: any) => {
            this.addNewTemplate(item.emailContentId, item.type);
          });
          this.setEmailTemplates.emit(this.templateGroupArrays);
          this.enableBtn.emit(true);
        });
    }

    this.communicationService.getBusinessCodesOfEmail().subscribe(data => {
      this.templateTypes = data.data.BusinessCodeGroup[0].businessCodes;
    });

    this.route.params.subscribe(params => {
      this.companyId = params['companyId'];
    });
  }

  getSelectedTemplates() {
    return this.templateGroupArrays.map(item => {
      return item.formGroup.controls['templateType'].value;
    });
  }

  customTemplatTypeValidator(
    control: AbstractControl,
    templateGroupArrays: template[]
  ): { [key: string]: any } | null {
    let templateTypes = templateGroupArrays?.map(
      item => item.formGroup.get('templateType')?.value
    );
    if (this.hasDuplicates(templateTypes)) {
      return { 'cannot have duplicate templates': true };
    }
    return null;
  }
  hasDuplicates(templates: any[]) {
    return new Set(templates).size !== templates.length;
  }
  addNewTemplate(templateName?: string, templateType?: string | null) {
    let templateNames: any[] = [];
    this.communicationService
      .getTemplatesByBusinessCode(templateType || '', this.companyId)
      .subscribe(data => {
        templateNames = data.data.EmailContentSelect;

        this.templateGroupArrays.push({
          formGroup: new FormGroup({
            templateType: new FormControl(templateType || '', [
              Validators.required,
              c => this.customTemplatTypeValidator(c, this.templateGroupArrays)
            ]),
            templateName: new FormControl(
              templateName || '',
              Validators.required
            )
          }),
          templateNames: templateNames,
          templateTypes: [
            ...this.templateTypes.filter(
              item => !this.existingTemplates.includes(item)
            )
          ]
        });

        this.existingTemplates.push(templateType);
      });
  }
}
