<tix-card>
  <tix-card-header *ngIf="showHeader">
    <tix-card-title>Media</tix-card-title>
  </tix-card-header>
  <tix-card-content
    [ngClass]="{
      '!px-0': !showHeader
    }"
  >
    <form [formGroup]="mediaFilesForm" class="profile-content-layout">
      <ng-template [ngIf]="mediaFilesFormArray?.controls?.length ?? 0 > 0">
        <div
          class="mat-form-field-media"
          [formArrayName]="MEDIA_FILES_FORM_NAME"
          *ngFor="let group of mediaFilesFormArray.controls; let i = index"
        >
          <mat-form-field [formGroupName]="i" class="inline-input-select">
            <mat-label>Media Files</mat-label>
            <input
              matInput
              type="text"
              formControlName="value"
              [readonly]="keepDisableSaveBtn"
            />
            <mat-select
              formControlName="type"
              [disabled]="keepDisableSaveBtn || !editPermission"
            >
              <mat-option
                *ngFor="let mediaFilesType of mediaFilesTypes"
                [value]="mediaFilesType.name"
                >{{ mediaFilesType.name }}</mat-option
              >
            </mat-select>
            <mat-error>media file url is invalid</mat-error>
          </mat-form-field>
          <button
            *ngIf="editPermission === true"
            (click)="openUploadDialog(i)"
            tix-button
            color="light"
            class="mr-1 mat-form-field-icon !ring-0"
          >
            <tix-icon svgIcon="upload" class="upload-icon"></tix-icon>
          </button>
          <button
            *ngIf="editPermission === true"
            (click)="confirmDelete(group, i)"
            tix-button
            color="light"
            class="mat-form-field-icon"
          >
            <tix-icon svgIcon="trash"></tix-icon>
          </button>
        </div>
      </ng-template>
    </form>

    <button
      tix-flat-button
      *ngIf="editPermission === true"
      (click)="addNewMediaFile()"
    >
      Add another
    </button>
  </tix-card-content>
  <tix-card-actions
    [ngClass]="{
      '!px-0 !py-0': !showHeader
    }"
    align="end"
  >
    <div class="self-center mr-auto">
      <ng-content select="[secondaryAction]"></ng-content>
    </div>
    <button
      (click)="emitMediaFiles()"
      *ngIf="editPermission === true"
      [disabled]="!mediaFilesForm?.valid || !mediaFilesForm?.dirty || isloading"
      tix-button
    >
      Save
      <span class="ml-1" *tixBtnLoading="isloading"></span>
    </button>
  </tix-card-actions>
</tix-card>
