import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Maybe,
  TixBusinessCodeGroup,
  TixDeleteEventInstanceTicketConfigByIdGQL,
  TixEventInstanceTicket,
  TixEventInstanceTicketConfig,
  TixGetBusinessCodesByGroupNameQuery,
  TixGetVenueByIdQuery,
  TixTicketConfig,
  TixVenueTicket,
  TixVenueTicketConfig
} from '@tix/data-access';
import {
  tixConfirmDialogDangerConfig,
  TixConfirmDialogService
} from '@tix/shared/ui/components';
import { TixVenuesPartialState } from '@tix/venues/state';
import * as VenueActions from '@tix/venues/state/actions';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { TicketConfigurationExpandContent } from '../ticket-configuration-expand/ticket-configuration-expand.component';
import { TicketConfigurationSectionConfigComponent } from '../ticket-configuration-section-config/ticket-configuration-section-config.component';
import {
  createTicketConfigurationExpandContentForTicketConfiguration,
  createVenueTicketFromTicketExpandContent
} from './ticket-configuration-admin.converters';
import {
  calculateTotalTicketsForConfigurationContent,
  checkForErrorInside,
  createSectionConfigurationBlank,
  createTicketConfigurationBlank,
  getTouchedForms
} from './ticket-configuration-admin.helpers';
import * as fromVenues from '@tix/venues/state/selectors';
import {
  BUSINESS_CODE_GROUP_NAMES,
  TixBusinessGroup,
  TixBusinessGroupsService
} from '@tix/shared/state';
import { UserSelector } from '@tix/auth/state';

@Component({
  selector: 'tix-ticket-configuration-admin',
  templateUrl: './ticket-configuration-admin.component.html',
  styleUrls: ['./ticket-configuration-admin.component.scss']
})
export class TicketConfigurationAdminComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  companyId: string;

  @Input()
  venueId: string;

  @Input()
  editPermission?: boolean = true;

  @Input()
  venueTicketsInput: Maybe<
    TixVenueTicket[] | NonNullable<TixGetVenueByIdQuery['VenueByPK']>['tickets']
  >;

  @Input()
  eventInstanceTicketsInput: Maybe<any[]>;

  ticketTypeBusinessCodeGroup =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.TICKET_TYPE
    );

  @Input()
  inline = false;

  @Input()
  hideSaveBtn = false;

  @Input()
  disablePools = false;

  @Output()
  eventInstanceTicketUpdate = new EventEmitter<TixEventInstanceTicket>();

  @Output()
  isValidUpdate = new EventEmitter<boolean>();

  @Output()
  saved = new EventEmitter<void>();

  userId$ = this.store.select(UserSelector.getAuthenticatedUser).pipe(
    take(1),
    map(u => u?.uid)
  );
  @Input() showHeader = true;

  hasTriedToSave = false;
  venueTickets: TicketConfigurationExpandContent[] = [];
  formChangesSubscriptions: Subscription[] = [];
  readonly getSelectedLoadingTicketConfig$ = this.store.select(
    fromVenues.getSelectedLoadingTicketConfig
  );

  @ViewChildren(TicketConfigurationSectionConfigComponent)
  ticketConfigurationSectionComponent: QueryList<TicketConfigurationSectionConfigComponent>;

  get parentTicketIdsArray() {
    return (
      this.venueTickets[0].children
        ?.map(child => child.formGroup?.['value'].parentTicketId)
        .filter(parentTicketId => parentTicketId !== undefined || null) || []
    );
  }

  get containsNewSection() {
    for (const ticket of this.venueTickets) {
      for (const config of ticket.children ?? []) {
        if (!config.originalValue) return true;
      }
    }

    return false;
  }

  get isValid() {
    return this.venueTickets.every(
      ticketConfig => ticketConfig.mainSection.formControl?.valid
    );
  }

  get disableSaveButton() {
    if (
      this.venueTickets.some(
        ticketConfig =>
          (ticketConfig.mainSection.formControl?.valid &&
            ticketConfig.mainSection.formControl.touched) ||
          getTouchedForms(ticketConfig.children ?? []).length > 0
      )
    ) {
      return false;
    }
    return true;
  }

  constructor(
    private confirmDialog: TixConfirmDialogService,
    private store: Store<TixVenuesPartialState>,
    private deleteEventInstanceTicketConfig: TixDeleteEventInstanceTicketConfigByIdGQL,
    private businessGroupsService: TixBusinessGroupsService
  ) {}

  interval: number;

  ngOnInit(): void {
    if (this.eventInstanceTicketsInput) {
      this.convertToVenueTickets();
    }

    this.resetVenueTickets(this.venueTicketsInput as TixVenueTicket[]);
    this.interval = window.setInterval(() => {
      this.eventInstanceTicketUpdate.emit(
        this.convertToEventInstanceTickets()[0]
      );
      this.isValidUpdate.emit(this.isValid);
    }, 1000);

    let lastState = false;
    this.getSelectedLoadingTicketConfig$.subscribe(state => {
      if (lastState && !state) {
        this.saved.emit();
      }

      lastState = state;
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  convertToVenueTickets() {
    this.venueTicketsInput = this.eventInstanceTicketsInput?.map(
      (evTicket): TixVenueTicket => ({
        ...evTicket,
        __typename: 'VenueTicket',
        Configurations: evTicket.Configurations.map(
          (config: any): TixVenueTicketConfig => ({
            ...config,
            updatedAt: 'now()',
            __typename: 'VenueTicketConfig',
            venueTicketConfig: {}
          })
        ),
        Configurations_aggregate: {
          nodes: []
        }
      })
    );
  }

  convertToEventInstanceTickets(): TixEventInstanceTicket[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.venueTickets
      .map(ticket => createVenueTicketFromTicketExpandContent(ticket, ''))
      .map(
        (vTicket, i): TixEventInstanceTicket =>
          ({
            ...(this.eventInstanceTicketsInput?.[i] || {}),
            ...vTicket,
            __typename: 'EventInstanceTicket',
            Configurations: vTicket.Configurations.map(config => ({
              ...config,
              venueTicketConfig: undefined,
              __typename: 'EventInstanceTicketConfig'
            })) as unknown as TixEventInstanceTicketConfig[],
            Configurations_aggregate: {
              nodes: []
            }
          } as TixEventInstanceTicket)
      );
  }

  subscribeOnFormChanges(venueTickets: TicketConfigurationExpandContent[]) {
    this.formChangesSubscriptions.forEach(e => e.unsubscribe());
    this.formChangesSubscriptions = [];

    venueTickets.forEach((ticket, ticketIndex) => {
      const quantityObservers: Observable<string>[] = [];

      ticket.children?.forEach((config, configIndex) => {
        // this.formChangesSubscriptions.push(
        //   (
        //     config.formGroup?.get('parentTicketId')?.valueChanges ?? of('0')
        //   ).subscribe({
        //     next: e => {
        //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        //       this.venueTickets[ticketIndex].children![configIndex].formGroup![
        //         'value'
        //       ].parentTicketId = e;
        //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        //     }
        //   })
        // );

        this.formChangesSubscriptions.push(
          (
            config.formGroup?.get('quantity')?.valueChanges ?? of('0')
          ).subscribe({
            next: e => {
              if (
                this.venueTickets[ticketIndex].children?.[configIndex]
                  ?.secondarySection
              ) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.venueTickets[ticketIndex].children![
                  configIndex
                ].secondarySection.value = e;
              }
            }
          }),

          (config.formGroup?.get('section')?.valueChanges ?? of('')).subscribe({
            next: e => {
              if (
                this.venueTickets[ticketIndex].children?.[configIndex]
                  ?.mainSection
              ) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.venueTickets[ticketIndex].children![
                  configIndex
                ].mainSection.value = e;
              }
            }
          }),

          (config.formGroup?.get('name')?.valueChanges ?? of('')).subscribe({
            next: e => {
              if (
                this.venueTickets[ticketIndex].children?.[configIndex]
                  ?.extraSection
              ) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.venueTickets[ticketIndex].children![
                  configIndex
                ].extraSection.value = e;
              }
            }
          })
        );
        quantityObservers.push(
          config.formGroup
            ?.get('quantity')
            ?.valueChanges.pipe(
              startWith(config.formGroup?.get('quantity')?.value || '0')
            ) ?? of(0)
        );
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['venueTicketsInput']) {
    //   this.resetVenueTickets(changes['venueTicketsInput'].currentValue);
    // }
  }

  resetVenueTickets(venueTicketInput: Maybe<TixVenueTicket[]>) {
    this.venueTickets =
      venueTicketInput?.map(e =>
        createTicketConfigurationExpandContentForTicketConfiguration(e)
      ) ?? [];

    this.subscribeOnFormChanges(this.venueTickets);
  }

  createNewConfiguration() {
    this.venueTickets.push(createTicketConfigurationBlank());
    this.subscribeOnFormChanges(this.venueTickets);
  }

  createNewSectionConfiguration(
    ticketConfiguration: TicketConfigurationExpandContent
  ) {
    ticketConfiguration.children?.push(
      createSectionConfigurationBlank(ticketConfiguration)
    );
    this.subscribeOnFormChanges(this.venueTickets);
  }

  highlightSection(ticketConfiguration: TicketConfigurationExpandContent) {
    if (this.hasTriedToSave) {
      return checkForErrorInside(ticketConfiguration);
    }
    return false;
  }

  async onSaveClick() {
    if (this.venueTickets.some(checkForErrorInside)) {
      this.ticketConfigurationSectionComponent.forEach(c =>
        c.markAllAsTouched()
      );
      this.hasTriedToSave = true;
      return;
    }

    const userId = await this.userId$.toPromise();

    this.venueTickets
      .filter(ticket => getTouchedTickets(ticket))
      .map(ticket => removeUntouchedChildren(ticket))
      .forEach(ticket => {
        ticket.formGroup?.markAsUntouched();
        ticket.children?.forEach(child => child.formGroup?.markAsUntouched());
        this.venueTickets.forEach(t => {
          t.mainSection.formControl?.markAsUntouched();
          t.formGroup?.markAsUntouched();
        });
        console.log('ticket', ticket);
        this.store.dispatch(
          VenueActions.saveVenueTicketConfiguration({
            ticket: createVenueTicketFromTicketExpandContent(
              ticket,
              userId ?? ''
            ),
            venueId: this.venueId
          })
        );
      });
  }

  async deleteTicketConfiguration(
    ticketConfiguration: TicketConfigurationExpandContent,
    index: number
  ) {
    const originalTicketConfig =
      ticketConfiguration.originalValue?.ticketConfiguration;
    const confirmation = await this.confirmDialog.promptConfirm({
      ...tixConfirmDialogDangerConfig,
      confirmText: `Are you sure you want to delete the Ticket Configuration "${ticketConfiguration.mainSection.value}" and all of it's Section configurations?`
    });

    if (confirmation) {
      if (originalTicketConfig) {
        this.store.dispatch(
          VenueActions.deleteVenueTicket({ venueTicket: originalTicketConfig })
        );
        this.venueTickets.splice(index, 1);
        return;
      }
      this.venueTickets.splice(index, 1);
    }
  }

  async deleteSectionConfiguration(
    venueTicket: TicketConfigurationExpandContent,
    sectionConfiguration: TicketConfigurationExpandContent,
    index: number
  ) {
    const originalSectionConfig =
      sectionConfiguration.originalValue?.sectionConfiguration;
    if (originalSectionConfig) {
      const confirmation = await this.confirmDialog.promptConfirm({
        ...tixConfirmDialogDangerConfig,
        confirmText: `Are you sure you want to delete this section "${sectionConfiguration.mainSection.value}"`
      });
      if (confirmation) {
        if (this.eventInstanceTicketsInput?.length) {
          this.deleteEventInstanceTicketConfig
            .mutate({
              ticketConfigId:
                originalSectionConfig.ticketConfigId ||
                originalSectionConfig.TicketConfigurations?.ticketConfigId
            })
            .subscribe();
          sectionConfiguration.parent?.children?.splice(index, 1);
          return;
        }
        sectionConfiguration.parent?.children?.splice(index, 1);

        this.store.dispatch(
          VenueActions.deleteVenueTicketConfiguration({
            venueTicketConfiguration: originalSectionConfig,
            venueTicket: venueTicket.originalValue?.ticketConfiguration
          })
        );
      }

      return;
    }
    sectionConfiguration.parent?.children?.splice(index, 1);
  }
}

function getTouchedTickets(ticket: TicketConfigurationExpandContent) {
  if (
    ticket.children?.some(child => child.formGroup?.touched) ||
    ticket.mainSection?.formControl?.touched
  ) {
    return true;
  }
  return false;
}

function removeUntouchedChildren(ticket: TicketConfigurationExpandContent) {
  return {
    ...ticket,
    children: ticket.children?.filter(child => child.formGroup?.touched)
  } as TicketConfigurationExpandContent;
}
