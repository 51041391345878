import { Injectable } from '@angular/core';
import { TixCreateRedirectCodeGQL } from '@tix/data-access';
import { TixAuthService } from './auth.service';

@Injectable()
export class AuthRedirectService {
  constructor(
    private authService: TixAuthService,
    private createRedirectCodeMutation: TixCreateRedirectCodeGQL
  ) {}

  async createRedirectCode() {
    const token = await this.authService.getToken().toPromise();
    if (!token)
      throw new Error(
        'must be authenticated to execute a redirection with authentication'
      );

    const response = await this.createRedirectCodeMutation
      .mutate({ token })
      .toPromise();
    const redirectCode = response.data?.createRedirectCode;
    if (!redirectCode)
      throw new Error(
        'Failed to generate a redirect code.\n You can execute a normal redirection instead.'
      );

    return redirectCode;
  }

  async redirectTo(baseUrl: string, route: string) {
    const redirectCode = await this.createRedirectCode();

    const newUrl = new URL(baseUrl);
    newUrl.pathname = '/auth/login-with-redirect';
    newUrl.searchParams.set('redirectCode', encodeURIComponent(redirectCode));
    newUrl.searchParams.set('redirectRoute', encodeURIComponent(route));

    window.location.href = newUrl.toString();
  }
}
