import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelector } from '@tix/auth/state';
import {
  TixGetConfigurationItemsGQL,
  TixGetConfigurationItemsQuery,
  TixUpdateConfigurationValuesForEventInstanceGQL,
  TixUpdateConfigurationValuesForVenueGQL
} from '@tix/data-access';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export type TixConfigurationItemList =
  TixGetConfigurationItemsQuery['ConfigurationItem'];
export type TixConfigurationItem = TixConfigurationItemList[0];

export enum TIX_CONFIG_ITEM_IDS {
  EMAIL_ADDRESS_FOR_SALE_NOTIFICATION = '7ee7c714-be18-4922-b271-e3b1c60a9719',
  SEND_EMAIL_NOTIFICATION = 'e0cba607-adcb-4337-9296-08a5e8e25126',

  VENUE_HIVE_BRAND_ID = '69ec8789-2510-4bba-96ce-38cd2291c618',
  AUTHORIZATION_TO_SEND_HIVE_DATA = '44b88e1d-9ce8-448c-bd58-dad38aed422f',
  MESSENGER_SCRIPT_ID = '0f621bc0-555a-419b-8a23-fdeed2127030'
}

@Injectable({
  providedIn: 'root'
})
export class TixConfigurationItemsService {
  private configurationListBS = new BehaviorSubject<TixConfigurationItemList>(
    []
  );

  public configurationList$ = this.configurationListBS.asObservable();

  constructor(
    private getConfigItemsQuery: TixGetConfigurationItemsGQL,
    private updateEventConfigItemsMutation: TixUpdateConfigurationValuesForEventInstanceGQL,
    private updateVenueConfigItemsMutation: TixUpdateConfigurationValuesForVenueGQL,
    private store: Store
  ) {}

  private userId$ = this.store.select(UserSelector.getAuthenticatedUser).pipe(
    map(u => u?.uid),
    take(1)
  );

  public getConfigItems(): Observable<TixConfigurationItemList> {
    return this.configurationList$.pipe(
      take(1),
      switchMap(cache => {
        if (cache.length) return of(cache);

        return this.getConfigItemsQuery
          .fetch({})
          .pipe(map(res => res.data.ConfigurationItem));
      })
    );
  }

  public getConfigItem(
    itemId: string
  ): Observable<TixConfigurationItem | undefined> {
    return this.getConfigItems().pipe(
      map(items => {
        return items.find(item => item.configurationItemId === itemId);
      })
    );
  }

  public updateConfigValueForEvent(
    eventInstanceId: string,
    configItemId: string,
    value: string
  ) {
    return this.userId$.pipe(
      switchMap(uid =>
        this.updateEventConfigItemsMutation.mutate({
          eventInstanceId,
          configurationItemId: configItemId,
          objects: [
            {
              updatedAt: 'now()',
              updatedBy: uid,
              createdAt: 'now()',
              createdBy: uid,
              eventInstanceId,
              value: {
                data: {
                  configurationItemId: configItemId,
                  value: value,
                  updatedAt: 'now()',
                  updatedBy: uid
                }
              }
            }
          ]
        })
      )
    );
  }

  public updateConfigValueForVenue(
    venueId: string,
    configItemId: string,
    value: string
  ) {
    return this.userId$.pipe(
      switchMap(uid =>
        this.updateVenueConfigItemsMutation.mutate({
          venueId,
          configurationItemId: configItemId,
          objects: [
            {
              updatedAt: 'now()',
              updatedBy: uid,
              venueId,
              Value: {
                data: {
                  configurationItemId: configItemId,
                  value: value,
                  updatedAt: 'now()',
                  updatedBy: uid
                }
              }
            }
          ]
        })
      )
    );
  }
}
