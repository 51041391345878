import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LogsState } from './log.state';
import { LOGS_FEATURE_KEY } from './log.reducers';

export const getLogsState = createFeatureSelector<LogsState>(LOGS_FEATURE_KEY);

export const selectLogs = createSelector(
  getLogsState,
  (state: LogsState) => state.logs
);

export const selectNetworkCalls = createSelector(
  getLogsState,
  (state: LogsState) => state.networkCalls
);
