import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { UserSelector } from '@tix/auth/state';
import {
  TixAddressInsertInput,
  TixCreateNewVenueGQL,
  TixDeleteVenueAddressesGQL,
  TixDeleteVenueMediaFilesGQL,
  TixDeleteVenuePhoneNumbersGQL,
  TixDeleteVenueSocialMediasGQL,
  TixDeleteVenueWarningsGQL,
  TixFullVenueFragment,
  TixGetVenueByIdGQL,
  TixGetVenueBySlugGQL,
  TixGetVenuesByCompanyIdGQL,
  TixGetVenuesByEventAdminIdGQL,
  TixInsertAddressGQL,
  TixInsertMediaFileGQL,
  TixInsertPhoneNumberGQL,
  TixInsertSocialMediaGQL,
  TixInsertWarningGQL,
  TixMarkVenueAsDeletedGQL,
  TixMediaFileInsertInput,
  TixPhoneNumber,
  TixSocialMediaInsertInput,
  TixUpdateVenueByPkGQL,
  TixVenue,
  TixVenueListItemFragment,
  TixWarning
} from '@tix/data-access';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

export type TixVenueListElement = TixVenueListItemFragment;
export type TixVenueList = TixVenueListElement[];
export type TixFullVenue = TixFullVenueFragment;

const errorMessage = 'An error has ocurred';
const saveSuccessMessage = 'Your changes have been saved successfully!';

@Injectable({
  providedIn: 'root'
})
export class TixVenuesService {
  // This will associate company id to its venues list
  private venuesListsCache = new Map<string, TixVenueList>();
  // This will associate venue id to its data
  private fullVenuesCache = new Map<string, TixFullVenue>();

  // Subjects
  private isLoadingBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private venuesBS = new Map<string, BehaviorSubject<TixFullVenue | null>>();

  // Observables
  public isLoading$ = this.isLoadingBS.asObservable();

  private userId$ = this.store
    .select(UserSelector.getAuthenticatedUser)
    .pipe(map(user => user?.uid));

  constructor(
    private getVenuesByCompanyIdQuery: TixGetVenuesByCompanyIdGQL,
    private getVenuesByEventAdminIdQuery: TixGetVenuesByEventAdminIdGQL,
    private getVenueByIdQuery: TixGetVenueByIdGQL,
    private getVenueBySlugQuery: TixGetVenueBySlugGQL,
    private createVenueMutation: TixCreateNewVenueGQL,
    private updateVenueByIdMutation: TixUpdateVenueByPkGQL,
    private deleteVenuePhoneNumbersMutation: TixDeleteVenuePhoneNumbersGQL,
    private insertPhoneNumbersMutation: TixInsertPhoneNumberGQL,
    private deleteVenueWarningsMutation: TixDeleteVenueWarningsGQL,
    private insertVenueWarnings: TixInsertWarningGQL,
    private deleteVenueSocialMediasMutation: TixDeleteVenueSocialMediasGQL,
    private insertVenueSocialMedias: TixInsertSocialMediaGQL,
    private deleteVenueMediaFilesMutation: TixDeleteVenueMediaFilesGQL,
    private insertVenueMediaFiles: TixInsertMediaFileGQL,
    private deleteVenueAddressesMutation: TixDeleteVenueAddressesGQL,
    private insertVenueAddressMutation: TixInsertAddressGQL,
    private deleteVenueMutation: TixMarkVenueAsDeletedGQL,
    private snackbar: MatSnackBar,
    private store: Store
  ) {}

  private getVenueBSById(venueId: string) {
    if (!this.venuesBS.get(venueId)) {
      this.venuesBS.set(
        venueId,
        new BehaviorSubject<TixFullVenue | null>(null)
      );
    }

    return this.venuesBS.get(venueId) as BehaviorSubject<TixFullVenue | null>;
  }

  public clearCache() {
    this.venuesListsCache = new Map();
    this.fullVenuesCache = new Map();
  }

  public clearCacheForVenue(venueId: string) {
    this.fullVenuesCache.delete(venueId);
  }

  public getVenuesForCompany(companyId: string): Observable<TixVenueList> {
    if (this.venuesListsCache.get(companyId)) {
      return of(this.venuesListsCache.get(companyId) as TixVenueList);
    }

    this.isLoadingBS.next(true);

    return this.getVenuesByCompanyIdQuery
      .fetch(
        {
          companyId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(res => res.data.Venue),
        tap(venues => {
          this.venuesListsCache.set(companyId, venues);
          this.isLoadingBS.next(false);
        }),
        catchError((error, ...args) => {
          this.isLoadingBS.next(false);
          return throwError(error);
        })
      );
  }

  public getVenuesForEventAdmin(
    companyId: string,
    userId: string[]
  ): Observable<TixVenueList> {
    if (this.venuesListsCache.get(companyId)) {
      return of(this.venuesListsCache.get(companyId) as TixVenueList);
    }

    this.isLoadingBS.next(true);

    return this.getVenuesByEventAdminIdQuery
      .fetch(
        {
          companyId,
          userId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(res => res.data.Venue),
        tap(venues => {
          this.venuesListsCache.set(companyId, venues);
          this.isLoadingBS.next(false);
        }),
        catchError((error, ...args) => {
          this.isLoadingBS.next(false);
          return throwError(error);
        })
      );
  }

  public getVenueById(
    venueId: string,
    updateLoadingState = true
  ): Observable<TixFullVenue | null> {
    const venueBS = this.getVenueBSById(venueId);

    const cachedVenue = this.fullVenuesCache.get(venueId);

    if (cachedVenue) {
      venueBS.next(cachedVenue);
      return of(cachedVenue);
    }

    if (updateLoadingState) {
      this.isLoadingBS.next(true);
    }

    return this.getVenueByIdQuery
      .fetch(
        {
          venueId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .pipe(
        map(res => res.data.VenueByPK || null),
        tap(venue => {
          if (updateLoadingState) {
            this.isLoadingBS.next(false);
          }
          if (venue) {
            this.fullVenuesCache.set(venueId, venue);
            venueBS.next(venue);
          }
        }),
        // mergeMap(() => venueBS.asObservable()),
        catchError((error, ...args) => {
          if (updateLoadingState) {
            this.isLoadingBS.next(false);
          }
          return throwError(error);
        })
      );
  }

  public getVenueBySlug(slug: string): Observable<TixFullVenue | null> {
    return this.getVenueBySlugQuery
      .fetch({ slug })
      .pipe(map(res => res.data.Venue[0] || null));
  }

  public createVenue(
    companyId: string,
    venue: Partial<TixVenue>
  ): Observable<TixFullVenue | null> {
    return this.userId$.pipe(
      mergeMap(uid =>
        this.createVenueMutation
          .mutate({
            objects: {
              name: venue.name,
              description: venue.description,
              additionalInfo: venue.additionalInfo,
              url: venue.url,
              slug: venue.slug,
              companyId,
              updatedAt: 'now()',
              updatedBy: uid,

              state: venue.state || 'Test'
            }
          })
          .pipe(
            mergeMap(res =>
              this.getVenueById(
                res.data?.InsertVenue?.returning[0].venueId as string,
                false
              )
            ),
            tap(() => {
              this.clearCache();
              this.snackbar.open('The venue has been created successfully!');
            }),
            catchError((error, ...args) => {
              if (error.toString().includes('unique constraint "slug"')) {
                this.snackbar.open(
                  'The given slug has already been used by another venue'
                );
              } else {
                this.snackbar.open(errorMessage);
              }

              return throwError(error);
            })
          )
      )
    );
  }

  public updateVenueInfo(
    venueId: string,
    venue: Partial<TixFullVenue>
  ): Observable<TixFullVenue | null> {
    if (!venue) return of();
    const { name, additionalInfo, description, url, state, slug } = venue;
    return this.userId$.pipe(
      mergeMap(uid => {
        return this.updateVenueByIdMutation
          .mutate({
            venueId,
            additionalInfo,
            name,
            state: state || 'Test',
            description,
            url,
            slug,
            updatedAt: 'now()',
            updatedBy: uid
          })
          .pipe(
            tap(() => {
              this.snackbar.open(saveSuccessMessage);
              this.clearCache();
            }),
            switchMap(() => this.getVenueById(venueId, false)),
            catchError((error, ...args) => {
              if (error.toString().includes('unique constraint "slug"')) {
                this.snackbar.open(
                  'The given slug has already been used by another venue'
                );
              } else {
                this.snackbar.open(errorMessage);
              }
              return throwError(error);
            })
          );
      })
    );
  }

  public updateVenueAddress(
    venueId: string,
    address: Partial<TixAddressInsertInput>
  ): Observable<TixFullVenue | null> {
    delete address.addressId;

    return this.deleteVenueAddressesMutation
      .mutate({
        venueId
      })
      .pipe(
        switchMap(() =>
          this.userId$.pipe(
            mergeMap(uid =>
              this.insertVenueAddressMutation.mutate({
                objects: {
                  ...address,
                  updatedBy: uid,
                  updatedAt: 'now()',
                  venueAddress: {
                    data: {
                      venueId
                    }
                  }
                }
              })
            )
          )
        ),
        switchMap(() => {
          this.clearCache();
          this.snackbar.open(saveSuccessMessage);

          return this.getVenueById(venueId, false);
        }),
        catchError(error => {
          this.snackbar.open(errorMessage);
          return throwError(error);
        })
      );
  }

  public updateVenuePhoneNumbers(
    venueId: string,
    phoneNumbers: Partial<TixPhoneNumber>[]
  ): Observable<TixFullVenue | null> {
    return this.deleteVenuePhoneNumbersMutation
      .mutate({
        venueId
      })
      .pipe(
        switchMap(() =>
          this.userId$.pipe(
            mergeMap(uid =>
              this.insertPhoneNumbersMutation.mutate({
                objects: phoneNumbers.map(p => ({
                  phoneNumber: p.phoneNumber,
                  sameAsCompany: p.sameAsCompany,
                  countryCode: p.countryCode,
                  type: p.type,
                  state: p.state,
                  updatedAt: 'now()',
                  updatedBy: uid,
                  venuePhoneNumbers: {
                    data: [
                      {
                        venueId
                      }
                    ]
                  }
                }))
              })
            )
          )
        ),
        switchMap(() => {
          this.clearCache();
          this.snackbar.open(saveSuccessMessage);
          return this.getVenueById(venueId, false);
        }),
        catchError(error => {
          this.snackbar.open(errorMessage);
          return throwError(error);
        })
      );
  }

  public updateVenueWarnings(
    venueId: string,
    warnings: Partial<TixWarning>[]
  ): Observable<TixFullVenue | null> {
    return this.deleteVenueWarningsMutation
      .mutate({
        venueId
      })
      .pipe(
        switchMap(() =>
          this.userId$.pipe(
            mergeMap(uid =>
              this.insertVenueWarnings.mutate({
                objects: warnings.map(w => ({
                  message: w.message,
                  type: w.type,
                  updatedAt: 'now()',
                  updatedBy: uid,
                  venue_warning: {
                    data: [
                      {
                        venueId
                      }
                    ]
                  }
                }))
              })
            )
          )
        ),
        switchMap(() => {
          this.clearCache();
          this.snackbar.open(saveSuccessMessage);

          return this.getVenueById(venueId, false);
        }),
        catchError(error => {
          this.snackbar.open(errorMessage);
          return throwError(error);
        })
      );
  }

  public updateVenueSocialMedias(
    venueId: string,
    socialMedias: Partial<TixSocialMediaInsertInput>[]
  ): Observable<TixFullVenue | null> {
    return this.deleteVenueSocialMediasMutation
      .mutate({
        venueId
      })
      .pipe(
        switchMap(() =>
          this.userId$.pipe(
            mergeMap(uid =>
              this.insertVenueSocialMedias.mutate({
                objects: socialMedias.map(s => ({
                  ...s,
                  socialMediaId: undefined,
                  updatedAt: 'now()',
                  updatedBy: uid,
                  venueSocialMedia: {
                    data: [
                      {
                        venueId
                      }
                    ]
                  }
                }))
              })
            )
          )
        ),
        switchMap(() => {
          this.clearCache();
          this.snackbar.open(saveSuccessMessage);

          return this.getVenueById(venueId, false);
        }),
        catchError(error => {
          this.snackbar.open(errorMessage);
          return throwError(error);
        })
      );
  }

  public updateVenueMediaFiles(
    venueId: string,
    mediaFiles: Partial<TixMediaFileInsertInput>[]
  ): Observable<TixFullVenue | null> {
    return this.deleteVenueMediaFilesMutation
      .mutate({
        venueId
      })
      .pipe(
        switchMap(() =>
          this.userId$.pipe(
            mergeMap(uid =>
              this.insertVenueMediaFiles.mutate({
                objects: mediaFiles.map(m => ({
                  ...m,
                  mediaFileId: undefined,
                  name: m.name || '',
                  updatedAt: 'now()',
                  updatedBy: uid,
                  venueMediaFile: {
                    data: [
                      {
                        venueId
                      }
                    ]
                  }
                }))
              })
            )
          )
        ),
        switchMap(() => {
          this.clearCache();
          this.snackbar.open(saveSuccessMessage);
          return this.getVenueById(venueId, false);
        }),
        catchError(error => {
          this.snackbar.open(errorMessage);
          return throwError(error);
        })
      );
  }

  public async deleteVenue(venueId: string): Promise<void> {
    try {
      await this.deleteVenueMutation
        .mutate({
          venueId,
          updatedBy: await this.userId$.pipe(take(1)).toPromise()
        })
        .toPromise()
        .then(() => {
          this.snackbar.open(
            'Venue deleted successfully! redirect to venues list'
          );

          this.clearCache();
        });
    } catch (error) {
      this.snackbar.open(errorMessage);
    }
  }

  public getVenueRoute(venue: TixFullVenue | TixVenueListElement) {
    if (venue.slug) {
      return '/' + venue.slug;
    } else return '/venue/' + venue.venueId;
  }
}
