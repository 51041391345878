import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Maybe,
  TixAddress,
  TixBusinessCodeGroup,
  TixBusinessCodes,
  TixGetVenueByIdQuery
} from '@tix/data-access';
import { Observable, of } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { TixCheckboxChange } from './../../checkbox/checkbox.component';
const defaultCountryCode = 'US';
@Component({
  selector: 'tix-address-edit',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class TixAddressEditComponent implements OnInit, OnChanges {
  @Input() userId: string;
  @Input() addresses:
    | Maybe<Maybe<TixAddress>[]>
    | NonNullable<
        TixGetVenueByIdQuery['VenueByPK']
      >['addresses'][0]['address'][];
  @Input() states: Maybe<
    Pick<TixBusinessCodeGroup, 'description' | 'name' | 'busCodeGroupId'> & {
      businessCodes: Pick<TixBusinessCodes, 'name' | 'description'>[];
    }
  >;
  @Input() countries: Maybe<
    Pick<TixBusinessCodeGroup, 'description' | 'name' | 'busCodeGroupId'> & {
      businessCodes: Pick<TixBusinessCodes, 'name' | 'description'>[];
    }
  >;
  @Input() sameAsCompany: Maybe<boolean> = undefined;
  @Input() showSkipButton = false;
  @Input() isloading?: boolean | undefined | null;
  @Input()
  companyAddresses: Maybe<TixAddress>[];
  @Input() showHeader = true;
  sameAsCompanyChanged = false;
  @Input() keepDisableSaveBtn = false;
  @Output() save: EventEmitter<TixAddress> = new EventEmitter<TixAddress>();
  @Output() sameAsCompanyChange = new EventEmitter<TixCheckboxChange>();
  @Output() skipEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  previousFormValue?: AddressFormStruct;
  addressId?: string;

  @Input()
  editPermission?: boolean = true;

  // address: Address | null | undefined;
  get address(): Maybe<TixAddress> {
    if (this.addresses === null || !this.addresses?.length) {
      return null;
    }
    this.addressId = this.addresses[0]?.addressId || this.addressId;
    return {
      ...((this.addresses[0] as any).address || this.addresses[0]),
      addressId: this.addressId
    };
  }
  get pristineOrInvalid(): boolean {
    return !this.addressForm?.dirty || !this.addressForm?.valid;
  }

  get canSave() {
    return (
      this.addressForm.valid && !this.addressForm.pristine && !this.isloading
    );
  }

  filteredOptions$: Observable<
    Pick<TixBusinessCodes, 'name' | 'description'>[]
  >;

  addressForm: FormGroup = new FormGroup({
    addressId: new FormControl(this.address?.addressId || ''),
    streetAddress: new FormControl(this.address?.streetAddress || '', [
      Validators.required
    ]),
    suiteApartment: new FormControl(this.address?.suiteApartment || ''),
    city: new FormControl(this.address?.city || '', [Validators.required]),
    stateProvince: new FormControl(this.address?.stateProvince || '', [
      Validators.required
    ]),
    postalCode: new FormControl(this.address?.postalCode || '', [
      Validators.required
    ]),
    country: new FormControl(this.address?.countryCode || defaultCountryCode, [
      Validators.required
    ])
  } as AddressFormStruct<FormControl>);

  ngOnInit(): void {
    this.filteredOptions$ =
      this.addressForm.get('stateProvince')?.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || ''))
      ) || of([]);

    if (this.address?.sameAsCompany) this.patchFormWithCompanyAddress();
    else if (this.address)
      this.addressForm.patchValue({
        addressId: this.address?.addressId || '',
        streetAddress: this.address?.streetAddress || '',
        suiteApartment: this.address?.suiteApartment || '',
        city: this.address?.city || '',
        stateProvince: this.address?.stateProvince || '',
        postalCode: this.address?.postalCode || '',
        country: this.address?.countryCode || defaultCountryCode
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.editPermission) this.addressForm.disable();

    if (this.sameAsCompany) {
      return;
    }
    if (changes.states) {
      this.states = changes.states['currentValue'];
    }
    const address = getAddressFromChanges(changes);
    if (address) {
      this.addressForm?.patchValue({
        addressId: address?.addressId || '',
        streetAddress: address?.streetAddress || '',
        suiteApartment: address?.suiteApartment || '',
        city: address?.city || '',
        stateProvince: address?.stateProvince || '',
        postalCode: address?.postalCode || '',
        country: address?.countryCode || defaultCountryCode
      } as AddressFormStruct);
      if (!this.sameAsCompanyChanged) {
        this.addressForm?.markAsPristine();
      } else {
        this.sameAsCompanyChanged = false;
      }
    }
  }

  checkSameAsCompany(event: TixCheckboxChange) {
    this.sameAsCompany = event.checked;
    this.sameAsCompanyChange.emit(event);
    this.sameAsCompanyChanged = true;
    this.addressForm.markAsDirty();
    if (event.checked) {
      this.patchFormWithCompanyAddress();
    } else {
      this.patchFormWithPreviousValue();
    }
  }

  patchFormWithPreviousValue() {
    if (this.previousFormValue) {
      this.addressForm.patchValue(this.previousFormValue);
    }
  }

  patchFormWithCompanyAddress() {
    this.previousFormValue = this.addressForm.value;
    const address = this.companyAddresses[0];
    if (!address) return;
    this.addressForm.patchValue({
      addressId: this.address?.addressId || '',
      streetAddress: address?.streetAddress || '',
      suiteApartment: address?.suiteApartment || '',
      city: address?.city || '',
      stateProvince: address?.stateProvince || '',
      postalCode: address?.postalCode || '',
      country: address?.countryCode || defaultCountryCode
    } as AddressFormStruct);
  }

  onSave(): void {
    this.addressForm && this.addressForm.markAsPristine();
    const rawFormValue = this.addressForm.value as AddressFormStruct;
    const companyAddress: TixAddress = {
      addressId: rawFormValue.addressId,
      city: rawFormValue.city,
      sameAsCompany: this.sameAsCompany,
      countryCode: rawFormValue.country || defaultCountryCode,
      streetAddress: rawFormValue.streetAddress,
      state: 'Active',
      postalCode: rawFormValue.postalCode,
      suiteApartment: rawFormValue.suiteApartment,
      stateProvince: rawFormValue.stateProvince,
      updatedAt: 'now()',
      updatedBy: this.userId
    };
    this.save.emit(companyAddress);
  }

  skipThisCard(): void {
    this.skipEvent.emit(true);
  }

  private _filter(
    value: string
  ): Pick<TixBusinessCodes, 'name' | 'description'>[] {
    const filterValue = value.toLowerCase();
    const reMatch = new RegExp(`${filterValue}`, 'i');
    return (
      this.states?.businessCodes.filter(option =>
        option?.description?.match(reMatch)
      ) || []
    );
  }
}
type AddressFormStruct<T = string> = {
  addressId: T;
  streetAddress: T;
  suiteApartment: T;
  city: T;
  stateProvince: T;
  postalCode: T;
  country: T;
};
function getAddressFromChanges(changes: SimpleChanges): Maybe<TixAddress> {
  if (changes['addresses']?.currentValue?.length) {
    return changes['addresses'].currentValue[0].address as Maybe<TixAddress>;
  }
  return null;
}
