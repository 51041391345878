import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NetworkCall } from '../log.state';
import { addNetworkCall } from '../logs.actions';
import { JsonPipe } from '@angular/common';

@Injectable()
export class NetworkLoggerInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const startTime = Date.now();

    return next.handle(request).pipe(
      tap(
        event => {
          if (event.type === 4) {
            const duration = Date.now() - startTime;
            const networkCall: NetworkCall = {
              url: request.url,
              status: event.status,
              method: request.method,
              duration,
              timestamp: new Date(startTime),
              body: request.body
            };

            this.store.dispatch(
              addNetworkCall({
                networkCall
              })
            );
          }
        },
        error => {
          const duration = Date.now() - startTime;
          const networkCall: NetworkCall = {
            url: request.url,
            status: error.status || 0,
            method: request.method,
            duration,
            timestamp: new Date(startTime),
            body: request.body
          };

          this.store.dispatch(
            addNetworkCall({
              networkCall
            })
          );
        }
      )
    );
  }
}
