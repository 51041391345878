import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ReleaseRedirectService } from '@tix/auth/services';
import * as moment from 'moment';
import { Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TimerBaseComonent } from '../timer-base/timer-base-component';

const TIMER_DURATION_SECONDS = 60 * 2;
const RELEASE_CHECK_INTERVAL_SECONDS = 10;

@Component({
  selector: 'tix-continuous-release-checker',
  templateUrl: './continuous-release-checker.component.html'
})
export class ContinuousReleaseCheckerComponent
  extends TimerBaseComonent
  implements OnInit, OnDestroy
{
  constructor(private releaseRedirectService: ReleaseRedirectService) {
    super();
  }

  private destroyed = new Subject();

  @Input() companyId: string;
  @Output() redirect = new EventEmitter<boolean>();

  shouldRedirect$ = timer(0, RELEASE_CHECK_INTERVAL_SECONDS * 1000).pipe(
    takeUntil(this.destroyed),
    switchMap(() => this.releaseRedirectService.check(this.companyId)),
    tap(shouldRedirect => {
      if (!shouldRedirect) return this.stopTimer();

      if (!this.timerActive) {
        this.resetTimer(TIMER_DURATION_SECONDS);
        this.startTimer();
      }
    })
  );

  // Timer display
  timerRemainingTimeFormatted$ = this.timer$.pipe(
    map(timer => {
      return moment(timer * 1000).format('mm:ss');
    })
  );
  timerRemainingPercentage$ = this.timer$.pipe(
    map(timer => {
      return (timer / TIMER_DURATION_SECONDS) * 100;
    })
  );

  ngOnInit(): void {
    this.resetTimer(TIMER_DURATION_SECONDS);

    this.timerExpired$.pipe(takeUntil(this.destroyed)).subscribe(expired => {
      if (expired) {
        this.redirect.emit(true);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.stopTimer();
  }
}
