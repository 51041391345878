import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { Maybe, TixVenue } from '@tix/data-access';
import { TixBuyersPartialState } from '@tix/event-buyer/state';
import * as BuyerActions from '@tix/event-buyer/state';
import * as BuyerSelectors from '@tix/event-buyer/state';
import * as AuthSelectors from '@tix/auth/state/selectors';
import { TIX_CONFIG_ITEM_IDS } from '@tix/shared/state';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TixVenuesService } from '@tix/venues/state';
import { tap } from 'rxjs/operators';
import { ReleaseRedirectService } from '@tix/auth/services';

@Component({
  selector: 'tix-buyer-layout',
  templateUrl: './buyer-layout.component.html',
  styleUrls: ['./buyer-layout.component.scss']
})
export class TixBuyerLayoutComponent implements OnInit, OnDestroy {
  readonly venueInfo$: Observable<Maybe<TixVenue>> = this.store
    .select(BuyerSelectors.getVenuesInfo)
    .pipe(
      tap(venue => {
        if (venue)
          this.venueMessengerScript =
            venue.configurationValues.find(
              config =>
                config.Value?.configurationItemId ===
                TIX_CONFIG_ITEM_IDS.MESSENGER_SCRIPT_ID
            )?.Value?.value ?? null;
        this.onInitFinishedBS.next(true);
      })
    );

  userState$ = this.store.select(AuthSelectors.getUserStatus);

  private venueMessengerScript: string | null = null;

  private onInitFinishedBS = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly store: Store<TixBuyersPartialState>,
    private router: Router,
    public route: ActivatedRoute,
    private venuesService: TixVenuesService,
    private releaseService: ReleaseRedirectService,
    private renderer: Renderer2
  ) {}

  companyId: string;
  venueId: string;

  isLoading = true;

  async ngOnInit() {
    this.isLoading = true;

    const params = this.router.url.split('/');
    const validParams = ['venue', 'checkout', 'success'];

    this.onInitFinishedBS.asObservable().subscribe(async initialized => {
      if (initialized) this.injectChatWootScript();
    });

    if (validParams.includes(params[1])) {
      this.venueId = params[2].split('?')[0] || params[2];
      this.store.dispatch(BuyerActions.getVenueInfo({ venueId: this.venueId }));

      const venue = await this.venuesService
        .getVenueById(this.venueId)
        .toPromise();

      this.companyId = venue?.companyId ?? '';
    } else {
      const venueSlug = params[1].split('?')[0];
      const venue = await this.venuesService
        .getVenueBySlug(venueSlug)
        .toPromise();

      this.venueId = venue?.venueId ?? '';
      this.store.dispatch(BuyerActions.getVenueInfo({ venueId: this.venueId }));

      this.companyId = venue?.companyId ?? '';
    }

    this.checkReleaseAndRedirect();
  }

  async checkReleaseAndRedirect() {
    this.isLoading = true;

    const { willRedirect } = await this.releaseService
      .checkAndRedirect(this.companyId, false)
      .toPromise();
    // Keep showing loading state until redirection is successful
    if (willRedirect) return;

    this.isLoading = false;
  }

  injectChatWootScript() {
    if (document.getElementById('chatwoot') || !this.venueMessengerScript) {
      return;
    }
    const chatWootScript = this.renderer.createElement('script');

    const cleanedScript = this.extractScriptContent(this.venueMessengerScript);
    chatWootScript.text = cleanedScript;
    chatWootScript.setAttribute('id', 'chatwoot');
    this.renderer.appendChild(document.head, chatWootScript);
  }

  extractScriptContent(input: string): string {
    const match = input.match(/<script[^>]*>([\s\S]*?)<\/script>/i);

    return match ? match[1].trim() : input;
  }

  removeChatWootScript(): void {
    const script = document.getElementById('chatwoot');
    if (script) this.renderer.removeChild(document.head, script);
  }

  ngOnDestroy(): void {
    this.removeChatWootScript();
  }
}
