export interface Log {
  type: 'INFO' | 'LOG' | 'WARN' | 'INFO' | 'ERROR';
  message: [any?, ...any[]];
  timestamp: Date;
}

export interface NetworkCall {
  url: string;
  status: number;
  method: string;
  duration: number;
  timestamp: Date;
  body: string;
}

export interface LogsState {
  logs: Log[];
  networkCalls: NetworkCall[];
}

export const initialLogsState: LogsState = {
  logs: [],
  networkCalls: []
};
