<tix-card>
  <tix-card-header *ngIf="showHeader">
    <tix-card-title>Social Media</tix-card-title>
  </tix-card-header>
  <tix-card-content
    [ngClass]="{
      '!px-0': !showHeader
    }"
  >
    <form [formGroup]="socialMediasForm" class="profile-content-layout">
      <ng-template [ngIf]="socialMediasFormArray?.controls?.length ?? 0 > 0">
        <div
          class="mat-form-field-social-media"
          formArrayName="socialMedias"
          *ngFor="let group of socialMediasFormArray.controls; let i = index"
        >
          <mat-form-field [formGroupName]="i" class="inline-input-select">
            <mat-label>Social Media</mat-label>
            <input
              matInput
              formControlName="socialMediaUrl"
              [readonly]="keepDisableSaveBtn"
            />
            <mat-select
              formControlName="socialMediaType"
              [disabled]="keepDisableSaveBtn || !editPermission"
            >
              <mat-option
                *ngFor="let socialMediaType of socialMediaTypes"
                [value]="socialMediaType.name"
                >{{ socialMediaType.name }}</mat-option
              >
            </mat-select>
            <mat-error>social media is invalid</mat-error>
          </mat-form-field>
          <button
            *ngIf="editPermission"
            (click)="confirmDelete(group, i)"
            tix-button
            color="light"
            class="mat-form-field-icon"
          >
            <tix-icon svgIcon="trash"></tix-icon>
          </button>
        </div>
      </ng-template>
    </form>

    <button
      tix-flat-button
      *ngIf="editPermission"
      (click)="addNewSocialMedia()"
    >
      Add another
    </button>
  </tix-card-content>
  <tix-card-actions
    [ngClass]="{
      '!px-0 !py-0': !showHeader
    }"
    align="end"
  >
    <div class="mr-auto self-center">
      <ng-content select="[secondaryAction]"></ng-content>
    </div>
    <button
      *ngIf="editPermission"
      (click)="handleSaveClick()"
      [disabled]="
        !socialMediasForm.valid || !socialMediasForm.dirty || isloading
      "
      tix-button
    >
      Save
      <span class="ml-1" *tixBtnLoading="isloading"></span>
    </button>
  </tix-card-actions>
</tix-card>
