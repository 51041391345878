<div class="tix-company-sidebar-wrapper" [class.show-icons]="!expandSidebar">
  <div class="tix-company-sidebar-top-level">
    <button
      color="dark"
      class="tix-company-sidebar-button"
      routerLinkActive="active-sidebar-button"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="['/', 'company']"
      [matTooltip]="expandSidebar ? '' : 'Companies'"
      [matTooltipPosition]="matTooltipPosition"
    >
      <tix-icon svgIcon="view-grid"></tix-icon
      ><span class="company-sidebar-item-text">Companies</span>
    </button>
  </div>
  <ng-container *ngIf="userRolesInCompany$ | async as roles">
    <div class="tix-company-links-wrapper">
      <button
        *ngIf="isAdmin(roles)"
        color="dark"
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['/', 'company', (companyId$ | async) || 'add']"
        [matTooltip]="expandSidebar ? '' : 'Company'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="company"></tix-icon>
        <span class="company-sidebar-item-text">Company</span>
      </button>
      <button
        color="dark"
        *ngIf="
          isManager(roles) || isAdmin(roles) || isEventAdministrator(roles)
        "
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['venues']"
        [matTooltip]="expandSidebar ? '' : 'Venues'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="venue"></tix-icon>
        <span class="company-sidebar-item-text">Venues</span>
        <span class="tix-company-sidebar-badge">{{
          (venuesAggregate$ | async) ?? 0
        }}</span>
      </button>
      <button
        color="dark"
        *ngIf="
          isManager(roles) || isAdmin(roles) || isEventAdministrator(roles)
        "
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['events']"
        [matTooltip]="expandSidebar ? '' : 'Events'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="event"></tix-icon>
        <span class="company-sidebar-item-text">Events</span>
        <span class="tix-company-sidebar-badge">{{
          eventsAggregate$ | async
        }}</span>
      </button>
      <button
        *ngIf="isAdmin(roles)"
        color="dark"
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['staff']"
        [matTooltip]="expandSidebar ? '' : 'Staff'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="staff"></tix-icon>
        <span class="company-sidebar-item-text">Staff</span>
        <span class="tix-company-sidebar-badge">{{
          (contactsAggregate$ | async) ?? 0
        }}</span>
      </button>
      <button
        *ngIf="isAdmin(roles) || isEventAdministrator(roles)"
        color="dark"
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['customers']"
        [matTooltip]="expandSidebar ? '' : 'Customers'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="customers"></tix-icon>
        <span class="company-sidebar-item-text">Customers</span>
        <span class="tix-company-sidebar-badge">{{
          (customersAggregate$ | async) ?? 0
        }}</span>
      </button>
      <button
        *ngIf="isAdmin(roles)"
        color="dark"
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['discounts']"
        [matTooltip]="expandSidebar ? '' : 'Discounts'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="currency-dollar"></tix-icon>
        <span class="company-sidebar-item-text">Discounts</span>
      </button>
      <button
        *ngIf="
          isAdmin(roles) ||
          isBoxOfficeUser(roles) ||
          isEventAdministrator(roles)
        "
        color="dark"
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['dashboard']"
        [matTooltip]="expandSidebar ? '' : 'Dashboard'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="chart-pie"></tix-icon>
        <span class="company-sidebar-item-text">Dashboard</span>
      </button>
      <button
        *ngIf="isAdmin(roles) || isManager(roles)"
        color="dark"
        class="tix-company-sidebar-button hover:text-secondary"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="['Add-Ons']"
        [matTooltip]="expandSidebar ? '' : 'Add-Ons'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <mat-icon class="mr-2 tix-icon">extension</mat-icon>
        <span class="company-sidebar-item-text">Add-Ons</span>
      </button>
      <button
        *ngIf="
          isAdmin(roles) || isManager(roles) || isEventAdministrator(roles)
        "
        color="dark"
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['reports']"
        [matTooltip]="expandSidebar ? '' : 'Reports'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="document-report"></tix-icon>
        <span class="company-sidebar-item-text">Reports</span>
      </button>
      <button
        *ngIf="isAdmin(roles) || isManager(roles)"
        color="dark"
        class="tix-company-sidebar-button"
        routerLinkActive="active-sidebar-button"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="['communications']"
        [matTooltip]="expandSidebar ? '' : 'communications'"
        [matTooltipPosition]="matTooltipPosition"
      >
        <tix-icon svgIcon="chat-alt"></tix-icon>
        <span class="company-sidebar-item-text">Communications</span>
      </button>
    </div>
  </ng-container>
</div>
