import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '@angular/fire/auth';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ContinuousReleaseCheckerComponent } from './continuous-release-checker.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, AuthModule],
  declarations: [ContinuousReleaseCheckerComponent],
  exports: [ContinuousReleaseCheckerComponent]
})
export class ContinuousReleaseCheckerModule {}
