import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { TixCompanyPartialState } from '@tix/company/state';
import {
  Maybe,
  TixBusinessCodeGroup,
  TixEventCompanyVenue,
  TixEventInstance,
  TixEventPerformers,
  TixUser,
  TixUserRole
} from '@tix/data-access';
import {
  BUSINESS_CODE_GROUP_NAMES,
  TixBusinessGroupsService
} from '@tix/shared/state';
import * as TixEventsSelectors from '@tix/events/state';
import * as TixEventsActions from '@tix/events/state';
import { UserSelector } from '@tix/auth/state';
import { User } from '@tix/shared/models';
import { ActivatedRoute } from '@angular/router';
import { ComponentCanDeactivate } from '@tix/shared/ui/components';
import { TixEventInfoComponent } from 'libs/events/components/src/lib/event-info/event-info.component';
import { map, mergeMap, take } from 'rxjs/operators';
@Component({
  selector: 'tix-event-admin',
  templateUrl: './event-admin.component.html',
  styleUrls: ['./event-admin.component.scss']
})
export class TixEventAdminPage implements OnInit, ComponentCanDeactivate {
  eventId$ = this.route.params.pipe(map(params => params['eventId']));
  companyId$ = this.route.params.pipe(map(params => params['companyId']));

  readonly businessEventTypes$ =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.BUSINESS_EVENT_TYPE
    );
  readonly businessStatusLists$ =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.STATUS_TYPE
    );
  readonly campaignType$ = this.businessGroupsService.getBusinessGroupByName(
    BUSINESS_CODE_GROUP_NAMES.CAMPAIGN_TYPE
  );
  readonly selectedEventData$: Observable<TixEventCompanyVenue | undefined> =
    this.store.select(TixEventsSelectors.getSelectedEvent);
  readonly eventVenueList$: Observable<any | undefined> = this.companyId$.pipe(
    mergeMap(companyId =>
      this.store.select(UserSelector.getUserRolesInfo).pipe(
        take(1),
        mergeMap((authUser: Maybe<TixUser>) =>
          this.store.select(TixEventsSelectors.getVenueLists).pipe(
            map(venue => {
              console.log(venue, authUser);
              const companies = authUser?.userRoles
                .map((userRole: TixUserRole) => {
                  console.log(userRole);
                  if (userRole.role?.role === 'event_admin')
                    return userRole.companyId;
                })
                .filter(companyId => !!companyId);

              const isAuthUserEventAdmin = companies?.includes(companyId);
              if (!isAuthUserEventAdmin) {
                console.log('inside');
                return venue;
              }
              console.log(
                venue?.filter(venue =>
                  venue.events.some(event =>
                    event.eventAdmins?.includes(authUser?.userId)
                  )
                )
              );

              return venue?.filter(venue =>
                venue.events.some(event =>
                  event.eventAdmins?.includes(authUser?.userId)
                )
              );
            })
          )
        )
      )
    )
  );

  readonly eventTicketConfigurationList$: Observable<any | undefined> =
    this.store.select(TixEventsSelectors.getVenueTicketLists);
  readonly performerList$: Observable<TixEventPerformers[] | undefined> =
    this.store.select(TixEventsSelectors.getSelectedPerformers);
  readonly userState$: Observable<User | null> = this.store.select(
    UserSelector.getAuthenticatedUser
  );

  isEdit = true;

  @ViewChild(TixEventInfoComponent) eventInfoComp?: TixEventInfoComponent;

  canDeactivate() {
    if (this.eventInfoComp) {
      return this.eventInfoComp.canDeactivate();
    }
    return true;
  }

  constructor(
    private readonly store: Store<TixCompanyPartialState>,
    private route: ActivatedRoute,
    private businessGroupsService: TixBusinessGroupsService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(TixEventsActions.getVenueList());
    this.route.params.subscribe(value => {
      if (value.eventId === 'add') {
        this.isEdit = false;
      } else this.isEdit = true;
    });
  }
}
