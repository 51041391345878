import { NgModule } from '@angular/core';
import {
  AuthRedirectService,
  ReleaseRedirectService,
  TixAuthService
} from '@tix/auth/services';
import {
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth
} from '@angular/fire/auth';
import { getApp } from '@angular/fire/app';

import {
  TixAuthDirective,
  TixAuthorizedGuard,
  TixUnauthorizedGuard
} from '@tix/auth/guards';
@NgModule({
  imports: [
    provideAuth(() => {
      return initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver
      });
    })
  ],
  providers: [
    TixAuthService,
    AuthRedirectService,
    ReleaseRedirectService,
    TixAuthorizedGuard,
    TixUnauthorizedGuard
  ],
  declarations: [TixAuthDirective],
  exports: [TixAuthDirective]
})
export class TixAuthModule {}
