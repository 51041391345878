import { Injectable } from '@angular/core';
import {
  TixGetCustomerByDatesGQL,
  TixGetCustomerByDatesQuery,
  TixGetCustomerBySingleDateGQL,
  TixGetCustomerBySingleDateQuery,
  TixGetCustomerByCompanyIdGQL,
  TixGetCustomerByCompanyIdQuery,
  TixGetCustomerBySearchGQL,
  TixGetCustomerBySearchQuery,
  TixGetCustomerByInputGQL,
  TixGetCustomerByDatesForEventAdminGQL,
  TixGetCustomerByInputForAdminGQL,
  TixGetCustomerBySingleDateForEventAdminGQL,
  TixGetCustomerBySearchForEventAdminGQL
} from '@tix/data-access';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TixCustomerService {
  private customersBS = new BehaviorSubject<
    | TixGetCustomerByDatesQuery['CustomerSearch']
    | TixGetCustomerBySearchQuery['CustomerSearch']
  >([]);
  private isLoadingCustomersBS = new BehaviorSubject<boolean>(false);
  public isLoadingCustomers$ = this.isLoadingCustomersBS.asObservable();
  public customers$ = this.customersBS.asObservable();
  constructor(
    private readonly TixGetCustomerByDates: TixGetCustomerByDatesGQL,
    private readonly TixGetCustomerByDatesForEventAdmin: TixGetCustomerByDatesForEventAdminGQL,
    private readonly TixGetCustomerBySingleDate: TixGetCustomerBySingleDateGQL,
    private readonly TixGetCustomerBySingleDateForEventAdmin: TixGetCustomerBySingleDateForEventAdminGQL,
    private readonly TixGetCustomerByCompanyId: TixGetCustomerByCompanyIdGQL,
    private readonly TixGetCustomerBySearch: TixGetCustomerBySearchGQL,
    private readonly TixGetCustomerBySearchForEventAdmin: TixGetCustomerBySearchForEventAdminGQL,
    private readonly TixGetCustomerByInput: TixGetCustomerByInputGQL,
    private readonly TixGetCustomerByInputForEventAdmin: TixGetCustomerByInputForAdminGQL
  ) {}

  public getCustomerByCompanyId(
    companyId: string
  ): Observable<TixGetCustomerByCompanyIdQuery['CustomerSearch']> {
    this.isLoadingCustomersBS.next(true);
    return this.TixGetCustomerByCompanyId.fetch(
      {
        companyId: companyId
      },
      {
        fetchPolicy: 'network-only'
      }
    ).pipe(
      map(({ data }) => {
        this.isLoadingCustomersBS.next(false);
        this.customersBS.next(data.CustomerSearch);
        return data.CustomerSearch;
      })
    );
  }
  public getCustomerByDates(
    companyId: string,
    startDate: string,
    endDate: string
  ): Observable<TixGetCustomerByCompanyIdQuery['CustomerSearch']> {
    this.isLoadingCustomersBS.next(true);
    return this.TixGetCustomerByDates.fetch(
      {
        companyId: companyId,
        startDate: startDate,
        endDate: endDate
      },
      {
        fetchPolicy: 'network-only'
      }
    ).pipe(
      map(({ data }) => {
        this.isLoadingCustomersBS.next(false);
        this.customersBS.next(data.CustomerSearch);
        return data.CustomerSearch;
      })
    );
  }
  public getCustomerByDatesForEvenetAdmin(
    companyId: string,
    startDate: string,
    endDate: string,
    userId: string[]
  ): Observable<TixGetCustomerByCompanyIdQuery['CustomerSearch']> {
    this.isLoadingCustomersBS.next(true);
    return this.TixGetCustomerByDatesForEventAdmin.fetch(
      {
        companyId: companyId,
        startDate: startDate,
        endDate: endDate,
        userId: userId
      },
      {
        fetchPolicy: 'network-only'
      }
    ).pipe(
      map(({ data }) => {
        this.isLoadingCustomersBS.next(false);
        this.customersBS.next(data.CustomerSearch);
        return data.CustomerSearch;
      })
    );
  }

  getCustomerBySearchForEventAdmin(
    companyId: string,
    query: string,
    startDate: string,
    endDate: string,
    userId: string[]
  ): Observable<TixGetCustomerBySearchQuery['CustomerSearch']> {
    this.isLoadingCustomersBS.next(true);
    if (startDate === 'Invalid date' || endDate === 'Invalid date') {
      return this.TixGetCustomerByInputForEventAdmin.fetch(
        {
          companyId: companyId,
          input: '%' + query + '%',
          userId: userId
        },
        {
          fetchPolicy: 'network-only'
        }
      ).pipe(
        map(({ data }) => {
          this.isLoadingCustomersBS.next(false);
          this.customersBS.next(data.CustomerSearch);
          return data.CustomerSearch;
        })
      );
    } else if (startDate === endDate) {
      return this.TixGetCustomerBySingleDateForEventAdmin.fetch(
        {
          companyId: companyId,
          input: '%' + query + '%',
          date: startDate,
          userId: userId
        },
        {
          fetchPolicy: 'network-only'
        }
      ).pipe(
        map(({ data }) => {
          this.isLoadingCustomersBS.next(false);
          this.customersBS.next(data.CustomerSearch);
          return data.CustomerSearch;
        })
      );
    } else {
      return this.TixGetCustomerBySearchForEventAdmin.fetch(
        {
          companyId: companyId,
          input: '%' + query + '%',
          startDate: startDate,
          endDate: endDate,
          userId: userId
        },
        {
          fetchPolicy: 'network-only'
        }
      ).pipe(
        map(({ data }) => {
          this.isLoadingCustomersBS.next(false);
          this.customersBS.next(data.CustomerSearch);
          return data.CustomerSearch;
        })
      );
    }
  }

  getCustomerBySearch(
    companyId: string,
    query: string,
    startDate: string,
    endDate: string
  ): Observable<TixGetCustomerBySearchQuery['CustomerSearch']> {
    this.isLoadingCustomersBS.next(true);
    if (startDate === 'Invalid date' || endDate === 'Invalid date') {
      return this.TixGetCustomerByInput.fetch(
        {
          companyId: companyId,
          input: '%' + query + '%'
        },
        {
          fetchPolicy: 'network-only'
        }
      ).pipe(
        map(({ data }) => {
          this.isLoadingCustomersBS.next(false);
          this.customersBS.next(data.CustomerSearch);
          return data.CustomerSearch;
        })
      );
    } else if (startDate === endDate) {
      return this.TixGetCustomerBySingleDate.fetch(
        {
          companyId: companyId,
          input: '%' + query + '%',
          date: startDate
        },
        {
          fetchPolicy: 'network-only'
        }
      ).pipe(
        map(({ data }) => {
          this.isLoadingCustomersBS.next(false);
          this.customersBS.next(data.CustomerSearch);
          return data.CustomerSearch;
        })
      );
    } else {
      return this.TixGetCustomerBySearch.fetch(
        {
          companyId: companyId,
          input: '%' + query + '%',
          startDate: startDate,
          endDate: endDate
        },
        {
          fetchPolicy: 'network-only'
        }
      ).pipe(
        map(({ data }) => {
          this.isLoadingCustomersBS.next(false);
          this.customersBS.next(data.CustomerSearch);
          return data.CustomerSearch;
        })
      );
    }
  }
}
