import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TixCompanyPartialState } from '@tix/company/state';
import {
  InputMaybe,
  TixDeleteMediaFilesGQL,
  TixDeleteSocialMediasGQL,
  TixBusinessCodeGroup,
  TixContactOnConflict,
  TixDeleteCampaignTrackingGQL,
  TixEvent,
  TixEventCampaignTracking,
  TixEventCampaignTrackingArrRelInsertInput,
  TixEventCampaignTrackingInsertInput,
  TixEventInsertInput,
  TixEventInstance,
  TixEventInstanceArrRelInsertInput,
  TixEventInstanceTicket,
  TixEventInstanceTicketConfig,
  TixEventInstanceTicketConfigInsertInput,
  TixEventInstanceTicketInsertInput,
  TixGetContactByEmailAddressGQL,
  TixGetEventTicketsSoldCountForContactGQL,
  TixGetTicketsSoldForTicketConfigGQL,
  TixInsertCampaignTrackingGQL,
  TixInsertContactGQL,
  TixInsertMediaFileGQL,
  TixInsertNewEventInstanceTicketConfigGQL,
  TixInsertPaymentReservationTicketGQL,
  TixInsertSocialMediasGQL,
  TixMediaFile,
  TixMediaFileInsertInput,
  TixPaymentInsertInput,
  TixPerformer,
  TixSendETicketGQL,
  TixSocialMedia,
  TixSocialMediaInsertInput,
  TixUpdateContactByPkGQL,
  TixUpdateEventInstanceTicketByIdGQL,
  TixUpdateTicketConfigByPkGQL,
  TixUpsertContactGQL,
  TixUpsertCustomerGQL,
  TixGetEmailTemplateOfEventGQL,
  TixUuidComparisonExp,
  TixGetEmailTemplateByCompanyidGQL,
  TixVenue,
  TixInsertEmailTemplatesOfEventGQL,
  TixEventEmailUpdateGQL,
  TixVenueTicket
} from '@tix/data-access';
import * as TixEventsActions from '@tix/events/state';
import * as TixEventsSelectors from '@tix/events/state';
import * as eventActions from '@tix/events/state';

import { User } from '@tix/shared/models';
import {
  BUSINESS_CODE_GROUP_NAMES,
  TIX_CONFIG_ITEM_IDS,
  TixBusinessGroup,
  TixBusinessGroupsService,
  TixConfigurationItemsService
} from '@tix/shared/state';
import {
  ComponentCanDeactivate,
  FileUploadDialogComponent,
  TixConfirmDialogService
} from '@tix/shared/ui/components';
import { Maybe } from 'graphql/jsutils/Maybe';

import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { TixDialog } from '../../../../../shared/ui/components/src/lib/dialog';
import {
  createMediaArray,
  createMediaFormGroup,
  createSocialMediaArray,
  createSocialMediaFormGroup
} from '../media.utils';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { TicketConfigurationAdminComponent } from 'libs/venues/components/src/lib/ticket-configuration/ticket-configuration-admin/ticket-configuration-admin.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TixConfirmDeleteDialogService } from 'libs/shared/ui/components/src/lib/dialog/confirm-delete-dialog/confirm-delete-dialog.service';
import { EventsService } from '@tix/core/http/events';
import { Roles, UserSelector } from '@tix/auth/state';
import { Papa } from 'ngx-papaparse';
import { CustomerService } from '@tix/event-buyer/services';
import { TixContactConstraint, TixContactUpdateColumn } from '@tix/data-access';
import { Apollo } from 'apollo-angular';
import { CommunicationsService } from 'libs/communications/components/src/lib/services/communications.service';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TixStaffMemberList, TixStaffService } from '@tix/staff/state';
import { TixVenuesService } from '@tix/venues/state';
import { v4 as uuid } from 'uuid';
import { EventService } from '../product/services/event.service';
import { CloneDialogComponent } from './clone-dialog/clone-dialog.component';
import { AmplitudeService } from 'amplitude.service';
import * as CompanySelectors from '@tix/company/state/selectors';

export type CAMPAIGN_PIXEL = Maybe<
  Omit<TixEventCampaignTracking, 'campaign_tracking_id'> & { isEdit: boolean }
>;
@Component({
  selector: 'tix-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TixEventInfoComponent
  implements OnInit, OnChanges, ComponentCanDeactivate, AfterViewInit
{
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() eventTicketLists: Maybe<TixVenueTicket[]>;
  @Input() eventVenueList: Maybe<TixVenue[]> = [];
  @Input() eventTypeList: Maybe<TixBusinessGroup>;
  @Input() statusLists: Maybe<TixBusinessGroup>;
  @Input() campaignType: Maybe<TixBusinessGroup>;
  @Input() selectedEventData: Maybe<any>;
  @Input() userData: Maybe<User | null>;

  selectEmailTemplate: string;
  templateArr: any[];
  selectTemplateType: string | null | undefined;
  eventDetailForm: FormGroup;
  searchSubscription: Subscription | undefined;
  companyId: TixUuidComparisonExp;
  selectedCompanyId: string;
  eventId: TixUuidComparisonExp;
  eventInstanceId: string | null = null;
  selectedIndex: number;
  isEdit = false;
  venueErrorMessage = 'Venue is required. ';
  isAdd = false;
  sendEmail = false;
  fileUploadSuccess = false;
  importCustomerLoading = false;
  count = 0;
  numberOfCustomers = 1;
  errorList: any = [];
  businessSocialMediaTypes$ = this.businessGroupsService.getBusinessGroupByName(
    BUSINESS_CODE_GROUP_NAMES.SOCIAL_MEDIA_TYPE
  );
  businessMediaFilesTypes$ = this.businessGroupsService.getBusinessGroupByName(
    BUSINESS_CODE_GROUP_NAMES.EVENT_MEDIA_FILE_TYPE
  );

  eventTicketConfigurationList$ = this.store.select(
    TixEventsSelectors.getVenueTicketLists
  );

  selectedTicketConfig: Maybe<TixEventInstanceTicket>;
  selectedTicketValid = false;

  readonly businessTicketTypes$ =
    this.businessGroupsService.getBusinessGroupByName(
      BUSINESS_CODE_GROUP_NAMES.TICKET_TYPE
    );

  readonly companyId$: Observable<string | undefined> = this.store.select(
    CompanySelectors.getSelectedCompanyId
  );

  userRolesInCompany$ = this.companyId$.pipe(
    mergeMap(companyId =>
      this.staffService.getCurrentUserRolesInCompany(companyId as string)
    )
  );

  isSaving = false;

  notificationEmailControl = new FormControl('');
  wantsToReceiveEmailNotification = false;
  emailNotificationConfigItem$ = this.configItemsService.getConfigItem(
    TIX_CONFIG_ITEM_IDS.EMAIL_ADDRESS_FOR_SALE_NOTIFICATION
  );
  sendEmailNotificationConfigItem$ = this.configItemsService.getConfigItem(
    TIX_CONFIG_ITEM_IDS.SEND_EMAIL_NOTIFICATION
  );

  @ViewChild(TicketConfigurationAdminComponent)
  ticketConfigAdminComp?: TicketConfigurationAdminComponent;
  showCopiedSuccess = false;

  userId$ = this.store.select(UserSelector.getAuthenticatedUser).pipe(
    map(u => u?.uid),
    take(1)
  );
  venue: any;
  isTemplateFormValid() {
    if (!this.templateArr || this.templateArr.length == 0) {
      return true;
    }
    return !this.templateArr
      .map(item => {
        return item.formGroup.invalid;
      })
      .includes(true);
  }

  canDeactivate() {
    if (this.eventDetailForm.dirty) {
      return false;
    }
    return true;
  }
  filteredCompanyUser$: Observable<TixStaffMemberList>;

  constructor(
    private confirmDialogService: TixConfirmDialogService,
    private confirmDeleteDialogService: TixConfirmDeleteDialogService,
    private eventService: EventService,
    private dialog: TixDialog,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private readonly store: Store<TixCompanyPartialState>,
    private insertMediaFile: TixInsertMediaFileGQL,
    private insertSocialMedia: TixInsertSocialMediasGQL,
    private deleteSocialMedia: TixDeleteSocialMediasGQL,
    private updateContactByPk: TixUpdateContactByPkGQL,
    private deleteMediaFile: TixDeleteMediaFilesGQL,
    private insertContact: TixInsertContactGQL,
    private getContactByEmail: TixGetContactByEmailAddressGQL,
    private updateEventInstanceTicket: TixUpdateEventInstanceTicketByIdGQL,
    private sendETicket: TixSendETicketGQL,
    private getTicketsCount: TixGetTicketsSoldForTicketConfigGQL,
    private insertPayment: TixInsertPaymentReservationTicketGQL,
    private updateTicketConfigByPK: TixUpdateTicketConfigByPkGQL,
    private insertTicketConfig: TixInsertNewEventInstanceTicketConfigGQL,
    private configItemsService: TixConfigurationItemsService,
    private businessGroupsService: TixBusinessGroupsService,
    private upsertCustomer: TixUpsertCustomerGQL,
    private upsertContact: TixUpsertContactGQL,
    private snackBar: MatSnackBar,
    private papa: Papa,
    private customerService: CustomerService,
    private eventsService: EventsService,
    private communicationService: CommunicationsService,
    private getEmailTemplateOfEventQuery: TixGetEmailTemplateOfEventGQL,
    private getTicketCountSoldToContactQuery: TixGetEventTicketsSoldCountForContactGQL,
    private venueService: TixVenuesService,
    private apollo: Apollo,
    private staffService: TixStaffService,
    private insertCampaignTracking: TixInsertCampaignTrackingGQL,
    private deleteCampaignTracking: TixDeleteCampaignTrackingGQL,
    private amplitudeService: AmplitudeService
  ) {
    this.route.paramMap.subscribe(params => {
      this.companyId = params?.get('companyId') as TixUuidComparisonExp;
      this.eventId = params?.get('eventId') as TixUuidComparisonExp;
      this.eventInstanceId = params?.get('eventId');
      this.selectedCompanyId = params.get('companyId') as string;
    });
    this.isAdd = this.route.snapshot.url.map(e => e.path).includes('add');
  }
  pixelsList: CAMPAIGN_PIXEL[] = [];

  protected showCampaignPixelInput: boolean;

  get showCampaignPixels() {
    return this.showCampaignPixelInput;
  }
  set showCampaignPixels(value: boolean) {
    this.showCampaignPixelInput = value;
  }

  get campaignTypes(): Maybe<TixBusinessGroup['businessCodes']> {
    if (this.campaignType) {
      return this.campaignType.businessCodes;
    }
    return [];
  }

  isEventAdministrator(roles: Roles[]) {
    return roles.includes(Roles.EVENT_ADMINISTRATOR);
  }

  showNewPixelForm = false;

  addNewPixel() {
    this.pixelsList.push({
      campaign_name: '',
      campaignCode: '',
      campaignType: '',
      isEdit: true
    });
  }

  deletePixelFromPixelList(index: number) {
    if (index > -1 && index < this.pixelsList.length) {
      this.pixelsList.splice(index, 1);
    }
  }

  checkCampaign() {
    if (!this.showCampaignPixelInput) {
      if (this.pixelsList.length === 0) {
        this.pixelsList.push({
          campaign_name: '',
          campaignCode: '',
          campaignType: '',
          isEdit: true
        });
      }
      this.showCampaignPixels = true;
      return;
    } else {
      this.pixelsList = [];
      this.showCampaignPixels = false;
    }
  }

  get selectedEventDetail(): Maybe<TixEventInstance> {
    if (this.selectedEventData === null || !this.selectedEventData) {
      return null;
    }
    return this.selectedEventData;
  }

  get getUserId() {
    if (this.userData) {
      return this.userData.uid;
    }
    return '00fda0cf-5a5d-4821-9e05-d995720212ab';
  }

  get eventTypes() {
    if (this.eventTypeList) {
      return this.eventTypeList.businessCodes;
    }
    return [];
  }

  get statusList() {
    if (this.statusLists) {
      return this.statusLists.businessCodes;
    }
    return [];
  }

  get salesPageURL() {
    return this.eventService.getSalesPageURL(
      this.selectedEventData?.eventInstanceId,
      this.selectedEventData?.slug,
      this.venue,
      true
    );
  }

  eventAdmins: FormControl;

  selectedAdmins: string[];
  get selectedEventAdmins() {
    return this.selectedAdmins;
  }

  selectedUserChip(event: MatAutocompleteSelectedEvent): void {
    const contactId = event.option.value;

    if (
      !this.selectedAdmins?.find((adminId: string) => adminId === contactId)
    ) {
      this.selectedAdmins.push(contactId);
    }

    this.eventAdmins.setValue('');
  }

  removeEventAdmin(adminId: string) {
    const index = this.selectedAdmins.indexOf(adminId);
    console.log('index', this.selectedAdmins, index);
    if (index >= 0) this.selectedAdmins.splice(index, 1);
  }

  async handleSalesPageURLCopy() {
    await window.navigator.clipboard.writeText(this.salesPageURL);
    this.showCopiedSuccess = true;

    this.snackBar.open(
      "The URL to this event's sales page has been copied to your clipboard!"
    );

    setTimeout(() => {
      this.showCopiedSuccess = false;
    }, 700);
  }

  goSalesPage(): void {
    window.open(this.salesPageURL, '_blank');
  }

  get venueIdControl() {
    return this.eventDetailForm.get('venueId') as FormControl;
  }

  get selectedVenueId() {
    return this.venueIdControl.value;
  }

  ticketConfigValidUpdate(isValid: boolean) {
    this.selectedTicketValid = isValid;
  }

  get isGenericEvent(): boolean {
    if (this.isEdit && this.selectedEventDetail?.type === 'Event') return true;
    if (this.selectedEventDetail?.type === 'Event') return true;
    if (this.eventDetailForm.get('type')?.value === 'EVENT') return true;
    if (this.genericEventValidator === null) return true;

    return false;
  }

  genericEventValidator: ValidationErrors | null = Validators.required;

  get stopeSellingDate() {
    return !this.selectedEventData?.stopSellingDate
      ? null
      : this.selectedEventData?.stopSellingDate
      ? this.getLocalDateAsUTC(this.selectedEventData?.stopSellingDate)
      : this.selectedEventData?.date &&
        this.getLocalDateAsUTC(
          this.getDefaultStopSellingDate(this.selectedEventData?.date)
        );
  }

  get stopSellingTime(): string {
    return this.selectedEventData?.startTime
      ? this.selectedEventData?.stopSellingTime ||
          (this.selectedEventData?.startTime &&
            this.getDefaultStopSellingTime(this.selectedEventData?.startTime))
      : null;
  }

  getUser(user: TixStaffMemberList[0]): string {
    return `${user.contact?.firstName} ${user.contact?.lastName} (${user.contact?.emailAddress})`;
  }

  getAdminFullName(adminId: string): string {
    const user = this.companyEventAdmins.find(
      contact => contact.contact?.user?.userId === adminId
    )?.contact;
    if (!user?.firstName && !user?.lastName) return user?.emailAddress!;
    return `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  }

  companyEventAdmins: TixStaffMemberList = [];

  getEmailTemplateOfEvent() {
    this.getEmailTemplateOfEventQuery
      .fetch(
        {
          eventInstanceId: this.eventInstanceId
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
      .subscribe(data => {
        this.selectEmailTemplate =
          data.data?.EventEmailSelect[0]?.emailContentId;
        this.selectTemplateType =
          data.data?.EventEmailSelect[0]?.EventEmailContent?.type;
      });
  }

  ngOnInit(): void {
    this.count = 0;
    this.selectedAdmins = [...(this.selectedEventData?.eventAdmins ?? [])];
    this.filteredCompanyUser$ = this.store
      .select(UserSelector.getAuthenticatedUser)
      .pipe(
        take(1),
        mergeMap((authUser: User | null) =>
          this.staffService
            .getStaffListForCompany(this.companyId as string)
            .pipe(
              map(users =>
                users.filter((user: TixStaffMemberList[0]) => {
                  if (user.contactId === authUser?.uid) return false;

                  return user.contact?.user?.userRoles.some(
                    userRole => userRole.role?.role === 'event_admin'
                  );
                })
              )
            )
        ),
        tap(admins => (this.companyEventAdmins = admins))
      );
    this.eventAdmins = new FormControl('');
    this.eventDetailForm = new FormGroup({
      name: new FormControl(this.selectedEventData?.name, Validators.required),
      type: new FormControl(this.selectedEventData?.type, Validators.required),
      status: new FormControl(this.selectedEventData?.state, [
        Validators.required
      ]),
      slug: new FormControl(this.selectedEventData?.slug),
      description: new FormControl(this.selectedEventData?.description),
      additionalInfo: new FormControl(this.selectedEventData?.additionalInfo),
      date: new FormControl(
        this.selectedEventData?.date !== undefined
          ? this.getLocalDateAsUTC(this.selectedEventData.date)
          : '',
        Validators.required
      ),
      eventAdmins: this.eventAdmins,
      doorsOpen: new FormControl(
        this.selectedEventData?.doorsOpen ||
          (this.selectedEventData?.startTime &&
            this.getDefaultDoorsOpen(this.selectedEventData?.startTime))
      ),
      startTime: new FormControl(this.selectedEventData?.startTime),
      endTime: new FormControl(this.selectedEventData?.endTime),
      helpText: new FormControl(this.selectedEventData?.helpText),
      helpTextTitle: new FormControl(this.selectedEventData?.helpTextTitle),
      startSellingDate: new FormControl(
        this.getLocalDateAsUTC(
          this.selectedEventData?.startSellingDate ||
            this.getDefaultStartSellingDate()
        ),
        this.genericEventValidator
      ),
      startSellingTime: new FormControl(
        this.selectedEventData?.startSellingTime ||
          this.getDefaultStartSellingTime(),
        this.genericEventValidator
      ),
      stopSellingDate: new FormControl(
        this.stopeSellingDate,
        this.genericEventValidator
      ),
      stopSellingTime: new FormControl(
        this.stopSellingTime,
        this.genericEventValidator
      ),
      minimumAge: new FormControl(
        this.selectedEventData?.minimumAge
          ? this.selectedEventData?.minimumAge.toString()
          : null
      ),
      hidden: new FormControl(
        this.selectedEventData?.hidden ? this.selectedEventData?.hidden : false
      ),
      passcode: new FormControl(this.selectedEventData?.passcode),
      venueId: new FormControl(
        this.selectedEventData?.venueId,
        this.genericEventValidator
      ),
      venueTicketId: new FormControl(
        this.selectedEventData?.venueTicketId,
        this.genericEventValidator
      ),
      socialMediasForm: setupSocialMediasForm(
        this.selectedEventData?.socialMedia?.map(
          (sm: any) => sm?.socialMedia
        ) || []
      ),
      mediasForm: setupMediasForm(
        this.selectedEventData?.media?.map((m: any) => m?.mediaFile) || []
      )
    });
    const {
      venueTicketId,
      startSellingDate,
      stopSellingDate,
      startSellingTime,
      stopSellingTime
    } = this.eventDetailForm.controls;
    const controls = [
      venueTicketId,
      startSellingDate,
      stopSellingDate,
      startSellingTime,
      stopSellingTime
    ];
    if (this.isGenericEvent) this.genericEventValidator = null;
    if (this.genericEventValidator === null) {
      controls.forEach(control => {
        if (control) {
          control.clearValidators();
          control.disable();
          control.updateValueAndValidity();
        }
      });
    }

    this.eventDetailForm.get('type')?.valueChanges.subscribe(value => {
      if (value === 'Event') {
        this.genericEventValidator = null;
        controls.forEach(control => {
          if (control) {
            control.clearValidators();
            control.disable();
            control.updateValueAndValidity();
          }
        });
      } else {
        this.genericEventValidator = Validators.required;
        controls.forEach(control => {
          if (control) {
            control.setValidators(Validators.required);
            control.enable();
            control.updateValueAndValidity();
          }
        });
      }
    });

    this.route.params.subscribe(value => {
      if (value.eventId === 'add') {
        this.isEdit = false;
      } else this.isEdit = true;
    });

    this.eventDetailForm
      .get('venueTicketId')
      ?.valueChanges.pipe(
        switchMap(venueTicketId => {
          return this.eventTicketConfigurationList$.pipe(
            map(tickets => {
              return tickets?.find(
                ticket => ticket.venueTicketId === venueTicketId
              );
            })
          );
        })
      )
      .subscribe(val => val && this.loadTicketFromTemplate(val));

    this.loadEventTicketConfig();
    this.venueService.getVenueById(this.selectedVenueId).subscribe(venue => {
      if (venue) this.venue = venue;
    });

    if (
      this.selectedEventDetail?.campaign &&
      this.selectedEventDetail?.campaign.length > 0
    ) {
      this.pixelsList = this.selectedEventDetail?.campaign.map(
        (pixel: TixEventCampaignTracking) => {
          return { ...pixel, isEdit: false };
        }
      );
      this.showCampaignPixels = true;
    } else {
      this.showCampaignPixels = false;
    }
  }

  ngAfterViewInit(): void {
    this.userRolesInCompany$.subscribe(roles => {
      const isEventadmin = this.isEventAdministrator(roles);
      if (isEventadmin) {
        this.eventDetailForm.get('date')?.disable({ emitEvent: false });
        this.eventDetailForm
          .get('startSellingTime')
          ?.disable({ emitEvent: false });
        this.eventDetailForm
          .get('stopSellingTime')
          ?.disable({ emitEvent: false });
        this.eventDetailForm
          .get('stopSellingDate')
          ?.disable({ emitEvent: false });
        this.eventDetailForm
          .get('startSellingDate')
          ?.disable({ emitEvent: false });
        this.eventDetailForm.get('doorsOpen')?.disable({ emitEvent: false });
        this.eventDetailForm.get('startTime')?.disable({ emitEvent: false });
        this.eventDetailForm.get('endTime')?.disable({ emitEvent: false });
      }
    });
  }

  setEmailTemplates(templateArr: any[]) {
    this.templateArr = templateArr;
  }
  onFileUpload(event: any) {
    const file = event.target.files[0];

    this.papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: result => {
        this.importCustomers(result.data);
      }
    });
  }

  private getTicketsSoldToContact(ticketConfigId: string, contactId: string) {
    return this.getTicketCountSoldToContactQuery
      .fetch(
        {
          contactId,
          ticketConfigId
        },
        { fetchPolicy: 'no-cache' }
      )
      .pipe(map(res => res.data.TicketAggregate.aggregate?.count ?? 0))
      .toPromise();
  }

  private initializeImport(rows: any[]) {
    this.importCustomerLoading = true;
    this.numberOfCustomers = rows.length;
    this.errorList = [];
  }
  private handleError(operation: string, error: any) {
    this.errorList.push({ operation, error });
  }
  private async handleCustomer(row: any, index: number): Promise<any> {
    let customer: any;
    const customerData = {
      firstName:
        row.Name.split(' ').length === 3
          ? row.Name.split(' ')[0] + ' ' + row.Name.split(' ')[1]
          : row.Name.split(' ')[0],
      lastName:
        row.Name.split(' ').length === 3
          ? row.Name.split(' ')[2]
          : row.Name.split(' ')[1],
      emailAddress: row.Email,
      phoneNumber: row.MobilePhone.replace(/-/g, ''),
      sms: true,
      emails: true,
      stAddress: row.BillingAddress,
      city: row.BillingCity,
      countryCode: 'USA',
      stateProvince: row.BillingState,
      postalCode:
        row.BillingPostal.length < 5
          ? row.BillingPostal.padStart(5, '0')
          : row.BillingPostal.length > 5 && row.BillingPostal.length < 10
          ? row.BillingPostal.padStart(10, '0')
          : row.BillingPostal,
      suiteApartment: ''
    };

    try {
      customer = await this.upsertCustomer.mutate(customerData).toPromise();
    } catch (err: any) {
      this.handleError(
        'updating customer failed ' + row.Email + ', id: ' + (index + 1),
        err.message
      );
      throw err;
    }

    return customer;
  }
  private async handleContact(row: any, index: number): Promise<any> {
    let contact: any;

    try {
      contact = await this.getContactByEmail
        .fetch({ emailAddress: row.Email }, { fetchPolicy: 'no-cache' })
        .pipe(map(res => res.data.Contact[0]))
        .toPromise();
    } catch (err: any) {
      this.handleError(
        'getting contact failed with this email ' +
          row.Email +
          ', id: ' +
          (index + 1),
        err.message
      );
      throw err;
    }

    const customerData = {
      fname:
        row.Name.split(' ').length === 3
          ? row.Name.split(' ')[0] + ' ' + row.Name.split(' ')[1]
          : row.Name.split(' ')[0],
      lname:
        row.Name.split(' ').length === 3
          ? row.Name.split(' ')[2]
          : row.Name.split(' ')[1],
      email: row.Email,
      phoneNumber: row.MobilePhone.replace(/-/g, ''),
      sms: true,
      emails: true,
      address: row.BillingAddress,
      city: row.BillingCity,
      country: 'USA',
      stateProvince: row.BillingState,
      postalCode:
        row.BillingPostal.length < 5
          ? row.BillingPostal.padStart(5, '0')
          : row.BillingPostal.length > 5 && row.BillingPostal.length < 10
          ? row.BillingPostal.padStart(10, '0')
          : row.BillingPostal,
      apartment: ''
    };

    try {
      contact = await this.upsertContact
        .mutate({
          emailAddress: row.Email,
          firstName: customerData.fname,
          lastName: customerData.lname,
          updatedAt: 'now()'
        })
        .pipe(map(res => res.data?.InsertContact?.returning[0]))
        .toPromise();
    } catch (err: any) {
      this.handleError(
        'updating contact failed ' + row.Email + ', id: ' + (index + 1),
        err.message
      );
      throw err;
    }

    return contact;
  }
  private async handleReservation(
    row: any,
    contactId: string,
    index: number
  ): Promise<any> {
    let ticketsToImport = this.selectedTicketConfig?.Configurations.filter(
      t => t.TicketConfigurations.name === row.TicketName
    ) as TixEventInstanceTicketConfig[];
    if (ticketsToImport.length === 0) {
      this.handleError(
        'Ticket Name not available in the configuration ' +
          row.TicketName +
          'email: ' +
          row.Email +
          ', id: ' +
          (index + 1),
        'add this ticket to this event'
      );
      return;
    }

    const ticketToImport = ticketsToImport[0];

    const nTicketsSoldToContact = await this.getTicketsSoldToContact(
      ticketToImport.TicketConfigurations.ticketConfigId,
      contactId
    );

    let ticketsSold = await this.getTicketsCount
      .fetch(
        {
          ticketConfigId: ticketToImport.TicketConfigurations.ticketConfigId
        },
        { fetchPolicy: 'no-cache' }
      )
      .pipe(map(res => res.data?.TicketAggregate?.aggregate?.count as number))
      .toPromise();
    let parentTicket;
    let totalSeats = ticketToImport.TicketConfigurations
      ?.noOfSeats as unknown as number;
    if (ticketsToImport[0].TicketConfigurations.parentTicketId) {
      parentTicket = this.selectedTicketConfig?.Configurations.filter(
        t =>
          t.TicketConfigurations.ticketConfigId ===
          ticketsToImport[0].TicketConfigurations.parentTicketId
      ) as TixEventInstanceTicketConfig[];
      totalSeats = parentTicket[0].TicketConfigurations
        ?.noOfSeats as unknown as number;
      ticketsSold = await this.getTicketsCount
        .fetch(
          {
            ticketConfigId:
              ticketsToImport[0].TicketConfigurations.parentTicketId
          },
          { fetchPolicy: 'no-cache' }
        )
        .pipe(map(res => res.data?.TicketAggregate?.aggregate?.count as number))
        .toPromise();
    }

    const availableSeats = totalSeats - ticketsSold;

    console.log(ticketsSold);
    const numberOfTicketsToCreate = Math.max(
      0,
      row.TotalTix - nTicketsSoldToContact
    );

    ticketsToImport = Array.from(
      { length: numberOfTicketsToCreate },
      () => ticketToImport
    );

    if (ticketsToImport.length > availableSeats) {
      this.handleError(
        'Not enough available seats' + row.Email + ', id: ' + (index + 1),
        'add more tickets to this event'
      );
      throw new Error('Not enough available seats');
    }

    return ticketsToImport;
  }
  private buildReservationData(
    row: any,
    ticketsToImport: TixEventInstanceTicketConfig[],
    contactId: string
  ) {
    return {
      updatedAt: 'now()',
      createdAt: 'now()',
      updatedBy: this.userData?.uid,
      discountId: null,
      contactId,
      additionalComments: '',
      totalAmountPaid: '$0.00',
      subTotal: '$0.00',
      venueFee: '$0.00',
      platformFee: '$0.00',
      processingFee: '$0.00',
      agreeToTerms: true,
      discountAmount: '$0.00',
      reservationDate: 'now()',
      eventInstanceId: this.eventId,
      state: 'Pending',
      checkedInAt: undefined,
      tickets: {
        data: ticketsToImport.map(t => ({
          state: 'Pending',
          checkedInAt: undefined,
          ticketConfigId: t.TicketConfigurations.ticketConfigId,

          ticketType: t.TicketConfigurations.ticketType,

          section: t.TicketConfigurations.section,
          row: t.TicketConfigurations.row || '',
          seat: t.TicketConfigurations.seat,
          processingFee: t.TicketConfigurations.processingFee,
          ticketPrice: t.TicketConfigurations.ticketPrice,
          platformFee: t.TicketConfigurations.platformFee,
          startSellingDate: t.TicketConfigurations.startSellingDate,
          startSellingTime: t.TicketConfigurations.startSellingTime,
          stopSellingDate: t.TicketConfigurations.stopSellingDate,
          stopSellingTime: t.TicketConfigurations.stopSellingTime,
          eventInstanceId: this.eventId,
          contact: {
            data: {
              firstName: row.Name.split(' ')[0],
              lastName: row.Name.split(' ')[1],
              emailAddress: row.Email,
              contactId
            },
            on_conflict: {
              constraint: TixContactConstraint.EmailUnique,
              update_columns: [
                TixContactUpdateColumn.EmailAddress,
                TixContactUpdateColumn.FirstName,
                TixContactUpdateColumn.LastName
              ]
            }
          }
        }))
      },
      salesChannel: 'Imported'
    };
  }
  private async handlePayment(
    row: any,
    contact: any,
    ticketsToImport: any,
    index: number
  ): Promise<any> {
    let payment;

    try {
      payment = await this.insertPayment
        .mutate({
          insertPayment: {
            contactId: contact.contactId,
            totalAmount: 0,
            createdAt: 'now()',
            updatedAt: 'now()',
            createdBy: this.userData?.uid,
            updatedBy: this.userData?.uid,
            paymentProvider: 'Comp',
            paymentToken: null,
            cardToken: null,
            state: 'Paid',
            paymentResponse: 'Success',
            Reservation: {
              data: {
                state: 'Success',
                reservation: {
                  data: this.buildReservationData(
                    row,
                    ticketsToImport,
                    contact.contactId
                  )
                }
              }
            }
          } as InputMaybe<TixPaymentInsertInput | TixPaymentInsertInput[]>
        })
        .pipe(map(res => res.data?.InsertPayment?.returning[0]))
        .toPromise();
    } catch (err: any) {
      this.handleError(
        'saving payment for this contact failed ' +
          row.Email +
          ', id: ' +
          (index + 1),
        err.message
      );
      throw err;
    }

    const reservationId = payment?.Reservation?.reservation?.reservationId;
    return reservationId;
  }
  private async sendTicketEmail(
    reservationId: any,
    row: any,
    index: number
  ): Promise<any> {
    try {
      await this.sendETicket
        .mutate({
          data: {
            reservationId,
            baseURL: window.location.origin + '/check-ticket'
          }
        })
        .toPromise();
    } catch (err: any) {
      throw {
        operation:
          'sending E ticket email failed ' + row.Email + ', id: ' + (index + 1),
        error: err.message
      };
    }
  }
  private async importRow(row: any, index: number) {
    const validationErrors = this.validateRow(row);
    if (validationErrors.length > 0)
      return this.handleError(
        'Validation failed for row with email ' +
          row.Email +
          ', id: ' +
          (index + 1),
        validationErrors
      );

    try {
      await this.handleCustomer(row, index);
      const contact = await this.handleContact(row, index);
      const ticketsToImport = await this.handleReservation(
        row,
        contact.contactId,
        index
      );
      if (ticketsToImport.length) {
        const reservationId = await this.handlePayment(
          row,
          contact,
          ticketsToImport,
          index
        );
        if (this.sendEmail) {
          await this.sendTicketEmail(reservationId, row, index);
        }
      }

      this.count++;
    } catch (err: any) {
      this.handleError(
        'Processing row failed ' + row.Email + ', id: ' + (index + 1),
        err.message
      );
    }
  }
  private finalizeImport() {
    this.fileUploadSuccess = true;
    this.importCustomerLoading = false;
    if (this.errorList.length === 0) {
      this.snackBar.open('All customers imported with success!');
    } else {
      this.importCustomerLoading = false;
      this.snackBar.open('An error occurred, processing stopped.');
    }
  }

  async importCustomers(rows: any[]) {
    this.initializeImport(rows);

    const chunkSize = 20;

    const chunks = [];
    for (let i = 0; i < rows.length; i += chunkSize) {
      chunks.push(rows.slice(i, i + chunkSize));
    }

    for (const chunk of chunks) {
      const tasks = chunk.map((row, index) => this.importRow(row, index));
      await Promise.all(tasks);
    }

    this.finalizeImport();
  }

  validateRow(row: any): string {
    const errors: string[] = [];

    if (
      !row.Email ||
      !/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)*(\.[a-zA-Z]{2,4})$/.test(row.Email)
    ) {
      errors.push('Invalid email format');
    }

    if (!row.Name || row.Name.split(' ').length < 2) {
      errors.push('Name must include first and last name');
    }

    const ticketsToImport = this.selectedTicketConfig?.Configurations.filter(
      t => t.TicketConfigurations.name === row.TicketName
    ) as TixEventInstanceTicketConfig[];

    if (ticketsToImport.length === 0) {
      errors.push(
        "This customer's ticket name doesn't exist in this event configuration"
      );
    }

    return errors.join('\n and');
  }

  loadTicketFromTemplate(ticket: TixVenueTicket) {
    const idMap = new Map<string, string>();
    this.selectedTicketConfig = {
      ...ticket,
      Configurations: ticket.Configurations.map(config => {
        const id =
          idMap.get(config.TicketConfigurations?.ticketConfigId) || uuid();
        idMap.set(config.TicketConfigurations?.ticketConfigId, id);

        let parentId: string | null = null;
        if (config.TicketConfigurations?.parentTicketId) {
          parentId =
            idMap.get(config.TicketConfigurations.parentTicketId) || uuid();

          idMap.set(config.TicketConfigurations.parentTicketId, parentId);
        }
        return {
          ...config,
          ticketConfigId: id,
          TicketConfigurations: {
            ...config.TicketConfigurations,
            ticketConfigId: id,
            parentTicketId: parentId
          }
        };
      }),
      __typename: 'EventInstanceTicket',
      venueId: undefined,
      venueTicketId: undefined,
      eventInstanceId: this.selectedEventData?.eventInstanceId
    } as unknown as TixEventInstanceTicket;
  }

  loadEventTicketConfig() {
    const ticket =
      this.selectedEventData?.eventInstanceTickets?.[0] ||
      this.selectedTicketConfig;
    if (ticket) {
      this.selectedTicketConfig = ticket;
    }
  }

  onChangeDate(e: any): void {
    const selectedDate = e.target.value;
    this.eventDetailForm.controls['stopSellingDate'].setValue(
      this.getDefaultStopSellingDate(selectedDate)
    );
  }

  private getDefaultStartSellingDate() {
    return moment().format('YYYY-MM-DD');
  }

  private getDefaultStartSellingTime() {
    return moment().format('HH:mm');
  }

  private getDefaultStopSellingDate(eventDate: string) {
    return eventDate;
  }

  onStartTimeUpdate(e: any): void {
    const value = e.target.value;
    if (!value.length) return;
    try {
      this.eventDetailForm.controls['stopSellingTime'].setValue(
        this.getDefaultStopSellingTime(value)
      );
    } catch (e) {
      console.error(e);
    }

    try {
      this.eventDetailForm.controls['doorsOpen'].setValue(
        value ? this.getDefaultDoorsOpen(value) : ''
      );
    } catch (e) {
      console.error(e);
    }
  }

  private getDefaultDoorsOpen(startTime: string) {
    let hours = parseInt(startTime.split(':')[0]);
    const minutes = parseInt(startTime.split(':')[1]);
    if (hours === 0) hours = 23;
    else hours--;

    return `${this.format2Digit(hours)}:${this.format2Digit(minutes)}`;
  }

  private getDefaultStopSellingTime(startTime: string) {
    let hours = parseInt(startTime.split(':')[0]);
    const minutes = parseInt(startTime.split(':')[1]);
    if (hours === 23) {
      hours = 0;
      const stopDate = this.eventDetailForm.get('stopSellingDate')?.value;
      if (stopDate) {
        const date = new Date(stopDate);
        date.setDate(date.getDate() + 1);
        this.eventDetailForm.get('stopSellingDate')?.setValue(date);
      }
    } else hours++;

    return `${this.format2Digit(hours)}:${this.format2Digit(minutes)}`;
  }

  public onSelectVenue(venue: TixVenue): void {
    this.store.dispatch(
      TixEventsActions.getVenueTicketConfigurationList({
        venueId: venue?.venueId
      })
    );
  }

  public onChangeStatus(name: string): void {
    // CASE - VENDORS DATA BLANK ON NEW EVENT
    if (name === 'Active') {
      this.eventDetailForm?.get('venueId')?.setErrors(Validators.required);
      if (this.eventDetailForm?.get('venueId')?.value) {
        this.eventDetailForm?.get('venueId')?.setErrors(null);
      } else {
        if (this.eventVenueList?.length === 0) {
          this.venueErrorMessage =
            'Venue is required. Please save Status as Pending and set up Venue.';
        } else {
          this.venueErrorMessage = 'Venue is required';
        }
      }
    } else {
      this.eventDetailForm?.get('venueId')?.setErrors(null);
    }

    // CASE - PERFORMERS DATA BLANK ON NEW EVENT
  }

  async recurringDate() {
    const confirmText = `Stay tuned....`;
    const result = await this.confirmDialogService.promptConfirm({
      confirmText,
      title: 'COMING SOON',
      icon: 'information-circle',
      titleTheme: 'info',
      cancelButton: {
        visible: false
      },
      confirmButton: {
        theme: 'info',
        label: 'Ok'
      }
    });
  }

  async cloneEvent() {
    this.userRolesInCompany$.subscribe(async roles => {
      console.log('deleting the event...');
      const isEventAdmin = this.isEventAdministrator(roles);
      if (isEventAdmin) return;
      this.amplitudeService.trackEvent('Button Clicked: Clone Event');

      const rawFormValue = this.eventDetailForm.value;

      const userId = await this.userId$.toPromise();

      const filledSocialMedia =
        rawFormValue.socialMediasForm.socialMedia.filter((m: any) => !!m.url);
      const filledMedia = rawFormValue.mediasForm.media.filter(
        (m: any) => !!m.location
      );

      const campaignPixels = this.selectedEventDetail?.campaign.map(pixel => {
        const { __typename, ...cleanedPixel } = pixel;

        return {
          ...cleanedPixel,
          createdAt: 'now()',
          updatedAt: 'now()',
          createdBy: userId,
          updatedBy: userId,
          campaign_tracking_id: uuid()
        };
      });

      const eventInstanceObj: TixEventInstanceArrRelInsertInput = {
        data: [
          {
            campaign:
              campaignPixels && campaignPixels.length > 0
                ? {
                    data: campaignPixels
                  }
                : undefined,
            slug: rawFormValue.slug,
            name: rawFormValue.name,
            type: rawFormValue.type,
            eventAdmins: this.selectedEventAdmins,
            date: rawFormValue?.date,
            state: rawFormValue?.status,
            description: rawFormValue.description,
            additionalInfo: rawFormValue?.additionalInfo,
            doorsOpen: rawFormValue.doorsOpen || null,
            startTime: rawFormValue.startTime || null,
            endTime: rawFormValue.endTime || null,
            startSellingDate: rawFormValue.startSellingDate,
            startSellingTime: rawFormValue.startSellingTime,
            stopSellingDate: rawFormValue.stopSellingDate,
            stopSellingTime: rawFormValue.stopSellingTime,
            minimumAge: rawFormValue.minimumAge
              ? parseInt(rawFormValue.minimumAge)
              : null,
            hidden: rawFormValue.hidden,
            passcode: rawFormValue?.passcode,
            venueId: rawFormValue?.venueId,
            venueTicketId: rawFormValue.venueTicketId
              ? rawFormValue.venueTicketId
              : null,
            eventInstanceTickets:
              this.genericEventValidator === null
                ? null
                : {
                    data: [this.getTicketInsertInput(userId, true)]
                  },

            updatedAt: 'now()',
            updatedBy: userId,

            configurationItems: {
              data: [
                {
                  updatedAt: 'now()',
                  updatedBy: userId,
                  createdAt: 'now()',
                  createdBy: userId,
                  value: {
                    data: {
                      configurationItemId:
                        TIX_CONFIG_ITEM_IDS.EMAIL_ADDRESS_FOR_SALE_NOTIFICATION,
                      value: this.notificationEmailControl.value,
                      updatedAt: 'now()',
                      updatedBy: userId
                    }
                  }
                },
                {
                  updatedAt: 'now()',
                  updatedBy: userId,
                  createdAt: 'now()',
                  createdBy: userId,
                  value: {
                    data: {
                      configurationItemId:
                        TIX_CONFIG_ITEM_IDS.SEND_EMAIL_NOTIFICATION,
                      value: this.wantsToReceiveEmailNotification
                        ? 'True'
                        : 'False',
                      updatedAt: 'now()',
                      updatedBy: userId
                    }
                  }
                }
              ]
            },

            socialMedia: filledSocialMedia.length
              ? {
                  data: filledSocialMedia.map((m: any) => ({
                    socialMedia: {
                      data: {
                        ...m,
                        updatedAt: 'now()',
                        updatedBy: userId
                      }
                    }
                  }))
                }
              : undefined,
            media: filledMedia.length
              ? {
                  data: filledMedia.map((m: any) => ({
                    mediaFile: {
                      data: {
                        ...m,
                        updatedAt: 'now()',
                        updatedBy: userId
                      }
                    }
                  }))
                }
              : undefined
          }
        ]
      };
      const eventInputData: TixEventInsertInput = {
        companyId: this.companyId,
        name: rawFormValue.name,
        type: rawFormValue.type,
        state: rawFormValue.status,
        eventInstances: eventInstanceObj,
        updatedBy: userId
      };
      const dialogRef = this.dialog.open(CloneDialogComponent, {
        data: {
          eventInputData,
          statusList: this.statusList,
          name: `cloned: ${rawFormValue.name}`,
          state: rawFormValue?.status,
          products: this.selectedEventData.eventProduct,
          templatesList: this.templateArr?.map(item => {
            return {
              eventInstanceId: this.eventInstanceId,
              emailContentId: item?.formGroup?.controls['templateName']?.value
            };
          })
        }
      });
      dialogRef.afterClosed();

      // this.store.dispatch(eventActions.addNewEventInCompany({ addEventObj: eventInputData }));

      // const confirmText = `Stay tuned....`;
      // const result = await this.confirmDialogService.promptConfirm({
      //   confirmText,
      //   title: 'COMING SOON',
      //   icon: 'information-circle',
      //   titleTheme: 'info',
      //   cancelButton: {
      //     visible: false
      //   },
      //   confirmButton: {
      //     theme: 'info',
      //     label: 'Ok'
      //   }
      // });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const selectedData = getselectedEventDataFromChanges(changes);
    if (selectedData) {
      this.eventDetailForm?.controls['date'].setValue(
        moment(selectedData?.date).format('YYYY-MM-DD')
      );
      if (selectedData?.venueId && selectedData?.venueTicketId) {
        this.store.dispatch(
          TixEventsActions?.getVenueTicketConfigurationList({
            venueId: selectedData?.venueId
          })
        );
      }

      this.eventDetailForm?.patchValue({
        name: selectedData?.name,
        type: selectedData?.type,
        status: selectedData?.state,
        description: selectedData?.description,
        additionalInfo: selectedData?.additionalInfo,
        doorsOpen: selectedData?.doorsOpen,
        startTime: selectedData?.startTime,
        endTime: selectedData?.endTime,
        date: selectedData?.date,
        startSellingDate: selectedData?.startSellingDate,
        startSellingTime:
          selectedData?.startSellingTime || this.formatDateToTime(new Date()),
        stopSellingDate: selectedData?.stopSellingDate || selectedData.date,
        stopSellingTime: selectedData?.stopSellingTime,
        minimumAge: selectedData?.minimumAge
          ? selectedData?.minimumAge.toString()
          : null,
        hidden: selectedData?.hidden,
        passcode:
          selectedData?.passcode === 'N/A' ? null : selectedData?.passcode,
        venueId: selectedData?.venueId,
        venueTicketId: selectedData?.venueTicketId,
        socialMediasForm: setupSocialMediasForm(
          selectedData?.socialMedia?.map((sm: any) => sm?.socialMedia) || []
        ),
        mediasForm: setupMediasForm(
          selectedData?.media?.map((m: any) => m?.mediaFile) || []
        )
      });

      const emailNotificationConfiguration =
        selectedData.configurationItems.find(
          item =>
            item.value?.configurationItemId ===
            TIX_CONFIG_ITEM_IDS.EMAIL_ADDRESS_FOR_SALE_NOTIFICATION
        );
      const sendEmailNotificationConfig = selectedData.configurationItems.find(
        item =>
          item.value?.configurationItemId ===
          TIX_CONFIG_ITEM_IDS.SEND_EMAIL_NOTIFICATION
      );
      if (emailNotificationConfiguration?.value?.value) {
        this.notificationEmailControl.setValue(
          emailNotificationConfiguration.value.value
        );
      }
      if (sendEmailNotificationConfig?.value?.value) {
        this.wantsToReceiveEmailNotification =
          sendEmailNotificationConfig?.value?.value === 'True';
      }
    }
  }

  get pristineOrInvalid(): boolean {
    return this.eventDetailForm.pristine || this.eventDetailForm.invalid;
  }

  get formInvalid(): boolean {
    if (this.genericEventValidator === null)
      return this.eventDetailForm.invalid || !this.isTemplateFormValid();
    return (
      this.eventDetailForm.invalid ||
      !this.selectedTicketValid ||
      !this.isTemplateFormValid()
    );
  }

  getLocalDateAsUTC(date: any) {
    return moment(date).utc().toDate();
  }

  format2Digit(n: number) {
    return ('0' + n.toString()).slice(-2);
  }

  formatDateToTime(date: Date): string {
    return `${this.format2Digit(date.getHours())}:${this.format2Digit(
      date.getMinutes()
    )}`;
  }

  eventInstanceTicketUpdate(ticket: TixEventInstanceTicket) {
    this.selectedTicketConfig = ticket;
  }

  getTicketInsertInput(
    userId: string | undefined,
    setNewIds = false
  ): TixEventInstanceTicketInsertInput {
    if (!this.selectedTicketConfig) return {};
    delete this.selectedTicketConfig.__typename;
    delete this.selectedTicketConfig.updatedAt;
    delete this.selectedTicketConfig.updatedBy;
    const idMap = new Map<string, string>();

    const configurations = [...this.selectedTicketConfig.Configurations];
    const configurationsData = configurations.map(
      (config): TixEventInstanceTicketConfigInsertInput => {
        const id =
          idMap.get(config.TicketConfigurations?.ticketConfigId) || uuid();
        idMap.set(config.TicketConfigurations?.ticketConfigId, id);

        let parentId: string | null = null;
        if (config.TicketConfigurations?.parentTicketId) {
          parentId =
            idMap.get(config.TicketConfigurations.parentTicketId) || uuid();

          idMap.set(config.TicketConfigurations.parentTicketId, parentId);
        }

        return {
          ...config,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          venueTicketId: undefined,
          eventInstanceTicketId: undefined,
          eventInstanceTicketConfigId: undefined,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          __typename: undefined,

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          isNew: undefined,

          state: 'Active',
          ticketConfigId: undefined,
          totalTicketsByType: undefined,
          TicketConfigurations: {
            data: {
              ...config.TicketConfigurations,
              ticketConfigId: setNewIds
                ? id
                : config.TicketConfigurations.ticketConfigId,
              parentTicketId: setNewIds
                ? parentId
                : config.TicketConfigurations.parentTicketId,
              updatedAt: 'now()',
              updatedBy: userId,
              totalTicketsByType: undefined,
              startSellingDate: config.TicketConfigurations.startSellingDate,
              stopSellingDate: config.TicketConfigurations.stopSellingDate,
              startSellingTime: config.TicketConfigurations.startSellingTime,
              stopSellingTime: config.TicketConfigurations.stopSellingTime
            }
          }
        };
      }
    );
    return {
      ...this.selectedTicketConfig,
      eventInstanceTicketId: undefined,
      eventInstanceId: undefined,
      state: 'Active',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Configurations_aggregate: undefined,
      Configurations: {
        data: configurationsData
      }
    };
  }

  public async save(goToEventPage = false) {
    // enable all form controls to include their values in the form submission
    Object.keys(this.eventDetailForm.controls).forEach(key => {
      this.eventDetailForm.get(key)?.enable({ emitEvent: false });
    });

    const rawFormValue = this.eventDetailForm.value;
    const filledSocialMedia = rawFormValue.socialMediasForm.socialMedia.filter(
      (m: any) => !!m.url
    );
    const filledMedia = rawFormValue.mediasForm.media.filter(
      (m: any) => !!m.location
    );
    const userId = await this.userId$.toPromise();
    const campaignTracking: TixEventCampaignTrackingInsertInput[] =
      this.pixelsList.length > 0
        ? this.pixelsList
            .filter(pixel => pixel?.isEdit === false)
            .map((pixel: CAMPAIGN_PIXEL) => {
              const campaign = {
                campaignCode: pixel?.campaignCode,
                campaignType: pixel?.campaignType,
                campaign_name: pixel?.campaign_name,
                campaign_tracking_id: uuid(),
                createdAt: pixel?.createdAt ?? 'now()',
                createdBy: userId,
                eventInstanceId: this.eventId,
                updatedAt: 'now()',
                updatedBy: userId
              };
              if (this.isEdit) return campaign;
              else {
                const { eventInstanceId, ...cleanedCampaign } = campaign;
                return cleanedCampaign;
              }
            })
        : [];
    const eventInstanceObj: TixEventInstanceArrRelInsertInput = {
      data: [
        {
          name: rawFormValue.name,
          slug: rawFormValue.slug,
          type: rawFormValue.type,
          date: moment(rawFormValue?.date).format('YYYY-MM-DD'),
          state: rawFormValue?.status,
          eventAdmins: this.selectedEventAdmins,
          description: rawFormValue.description,
          additionalInfo: rawFormValue?.additionalInfo,
          doorsOpen: rawFormValue.doorsOpen || null,
          startTime: rawFormValue.startTime || null,
          endTime: rawFormValue.endTime || null,
          startSellingDate: moment(rawFormValue.startSellingDate).format(
            'YYYY-MM-DD'
          ),
          startSellingTime: rawFormValue.startSellingTime,
          stopSellingDate: moment(rawFormValue.stopSellingDate).format(
            'YYYY-MM-DD'
          ),
          stopSellingTime: rawFormValue.stopSellingTime,
          minimumAge: rawFormValue.minimumAge
            ? parseInt(rawFormValue.minimumAge)
            : null,
          hidden: rawFormValue.hidden,
          passcode: rawFormValue?.passcode,
          venueId: rawFormValue?.venueId,
          venueTicketId: rawFormValue.venueTicketId
            ? rawFormValue.venueTicketId
            : null,

          eventInstanceTickets: this.isGenericEvent
            ? null
            : {
                data: [this.getTicketInsertInput(userId)]
              },
          campaign:
            campaignTracking.length > 0
              ? {
                  data: campaignTracking
                }
              : undefined,
          updatedAt: 'now()',
          updatedBy: userId,

          configurationItems: {
            data: [
              {
                updatedAt: 'now()',
                updatedBy: userId,
                createdAt: 'now()',
                createdBy: userId,
                value: {
                  data: {
                    configurationItemId:
                      TIX_CONFIG_ITEM_IDS.EMAIL_ADDRESS_FOR_SALE_NOTIFICATION,
                    value: this.notificationEmailControl.value,
                    updatedAt: 'now()',
                    updatedBy: userId
                  }
                }
              },
              {
                updatedAt: 'now()',
                updatedBy: userId,
                createdAt: 'now()',
                createdBy: userId,
                value: {
                  data: {
                    configurationItemId:
                      TIX_CONFIG_ITEM_IDS.SEND_EMAIL_NOTIFICATION,
                    value: this.wantsToReceiveEmailNotification
                      ? 'True'
                      : 'False',
                    updatedAt: 'now()',
                    updatedBy: userId
                  }
                }
              }
            ]
          },

          socialMedia: filledSocialMedia.length
            ? {
                data: filledSocialMedia.map((m: any) => ({
                  socialMedia: {
                    data: {
                      ...m,
                      updatedAt: 'now()',
                      updatedBy: userId
                    }
                  }
                }))
              }
            : undefined,
          media: filledMedia.length
            ? {
                data: filledMedia.map((m: any) => ({
                  mediaFile: {
                    data: {
                      ...m,
                      updatedAt: 'now()',
                      updatedBy: userId
                    }
                  }
                }))
              }
            : undefined
        }
      ]
    };

    const eventInputData: TixEventInsertInput = {
      companyId: this.companyId,
      name: rawFormValue.name,
      type: rawFormValue.type,
      state: rawFormValue.status,
      eventInstances: eventInstanceObj,
      updatedBy: userId
    };

    if (this.isEdit) {
      this.isSaving = true;
      const updatedInputData = {
        ...eventInstanceObj.data[0],
        eventInstanceTickets: undefined,
        stopSellingTime: this.isGenericEvent
          ? null
          : eventInstanceObj.data[0].stopSellingTime,
        startSellingTime: this.isGenericEvent
          ? null
          : eventInstanceObj.data[0].startSellingTime
      };
      if (updatedInputData) {
        Object.assign(updatedInputData, {
          eventInstanceId: this.eventId,
          updatedBy: userId,
          updatedAt: 'now()'
        });
        delete updatedInputData.eventPerformers;
        delete updatedInputData.socialMedia;
        delete updatedInputData.media;
        delete updatedInputData.configurationItems;
        delete updatedInputData.campaign;
      }

      const socialMediaIds = this.selectedEventData?.socialMedia.map(
        (socialMedia: any) => socialMedia.socialMedia.socialMediaId
      );
      const mediaIds = this.selectedEventData?.media.map(
        (media: any) => media.mediaFile.mediaFileId
      );
      const campaignTrackingIds =
        (this.selectedEventData?.campaign &&
          this.selectedEventData?.campaign.length) > 0
          ? this.selectedEventData?.campaign.map(
              (campaign: any) => campaign.campaign_tracking_id
            )
          : [];
      await this.configItemsService
        .updateConfigValueForEvent(
          this.selectedEventData.eventInstanceId,
          TIX_CONFIG_ITEM_IDS.EMAIL_ADDRESS_FOR_SALE_NOTIFICATION,
          this.notificationEmailControl.value
        )
        .toPromise();

      await this.configItemsService
        .updateConfigValueForEvent(
          this.selectedEventData.eventInstanceId,
          TIX_CONFIG_ITEM_IDS.SEND_EMAIL_NOTIFICATION,
          this.wantsToReceiveEmailNotification ? 'True' : 'False'
        )
        .toPromise();

      await this.deleteMediaFile
        .mutate({
          ids: mediaIds
        })
        .toPromise();
      await this.deleteSocialMedia
        .mutate({
          ids: socialMediaIds
        })
        .toPromise();

      await this.communicationService.deleteEventEmail(this.eventInstanceId);

      await this.communicationService
        .insertEmailTemplatesOfEvent(
          this.templateArr?.map(item => {
            return {
              eventInstanceId: this.eventInstanceId,
              emailContentId: item.formGroup.controls['templateName'].value
            };
          })
        )
        .catch(err => {
          console.log('err', err);
        });

      await this.deleteCampaignTracking
        .mutate({ ids: campaignTrackingIds })
        .toPromise();
      await this.insertMediaFile
        .mutate({
          objects: filledMedia.map(
            (media: any): TixMediaFileInsertInput => ({
              location: media.location,
              fileType: media.fileType,
              name: '',
              updatedAt: 'now()',
              updatedBy: userId,
              eventMediaFile: {
                data: [
                  {
                    eventInstanceId: this.eventId
                  }
                ]
              }
            })
          )
        })
        .toPromise();
      await this.insertSocialMedia
        .mutate({
          objects: filledSocialMedia.map(
            (socialMedia: any): TixSocialMediaInsertInput => ({
              socialMediaType: socialMedia.socialMediaType,
              url: socialMedia.url,
              updatedAt: 'now()',
              updatedBy: userId,
              eventInstanceSocialMedia: {
                data: [
                  {
                    eventInstanceId: this.eventId
                  }
                ]
              }
            })
          )
        })
        .toPromise();

      await this.insertCampaignTracking
        .mutate({
          objects: campaignTracking
        })
        .toPromise();
      this.store.dispatch(
        eventActions.updateEventInCompany({ updatedObj: updatedInputData })
      );
      if (!this.isGenericEvent) {
        await this.updateEventInstanceTicket
          .mutate(
            {
              eventInstanceTicketId:
                this.selectedTicketConfig?.eventInstanceTicketId,
              _set: {
                updatedAt: 'now()',
                updatedBy: userId,
                description: this.selectedTicketConfig?.description,
                name: this.selectedTicketConfig?.name,
                state: this.selectedTicketConfig?.state,
                totalSeats: this.selectedTicketConfig?.totalSeats
              }
            },
            {
              fetchPolicy: 'no-cache'
            }
          )
          .toPromise();
        const promises = this.selectedTicketConfig?.Configurations.map(
          config => {
            const {
              isNew,
              TicketConfigurations: {
                name,
                noOfSeats,
                salesChannel,
                perOrderMax,
                perOrderMin,
                platformFee,
                processingFee,
                venueFee,
                helpText,
                helpTextTitle,
                row,
                seat,
                section,
                state,
                ticketPrice,
                ticketType,
                ticketConfigId,
                parentTicketId,
                includeFees,
                startSellingDate,
                startSellingTime,
                stopSellingDate,
                stopSellingTime
              }
            } = config as TixEventInstanceTicketConfig & { isNew: boolean };

            console.log(config);

            if (!isNew) {
              return this.updateTicketConfigByPK
                .mutate(
                  {
                    ticketConfigId,
                    _set: {
                      parentTicketId: parentTicketId || null,
                      name,
                      noOfSeats,
                      perOrderMax,
                      salesChannel,
                      helpText,
                      helpTextTitle,
                      perOrderMin,
                      platformFee,
                      processingFee,
                      venueFee,
                      row,
                      seat,
                      section,
                      state,
                      ticketPrice,
                      ticketType,
                      includeFees,
                      updatedAt: 'now()',
                      updatedBy: userId,
                      startSellingDate,
                      startSellingTime,
                      stopSellingDate,
                      stopSellingTime
                    }
                  },
                  {
                    fetchPolicy: 'no-cache'
                  }
                )
                .toPromise();
            } else {
              return this.insertTicketConfig
                .mutate(
                  {
                    object: {
                      eventInstanceTicketId:
                        this.selectedTicketConfig?.eventInstanceTicketId,
                      state: state || 'Active',
                      TicketConfigurations: {
                        data: {
                          ticketConfigId,
                          parentTicketId,
                          name,
                          noOfSeats,
                          helpText,
                          helpTextTitle,
                          salesChannel,
                          perOrderMax,
                          perOrderMin,
                          platformFee,
                          processingFee,
                          venueFee,
                          row,
                          seat,
                          section,
                          state: state || 'Active',
                          ticketPrice,
                          ticketType,
                          includeFees,
                          updatedAt: 'now()',
                          updatedBy: userId,
                          startSellingDate,
                          startSellingTime,
                          stopSellingDate,
                          stopSellingTime
                        }
                      }
                    }
                  },
                  {
                    fetchPolicy: 'no-cache'
                  }
                )
                .toPromise();
            }
          }
        );

        await Promise.all(promises || []);
      }
      this.store.dispatch(TixEventsActions.clearState());

      const eventInstanceId = this.eventInstanceId;
      if (eventInstanceId) {
        this.store.dispatch(
          TixEventsActions.loadEventById({ eventInstanceId })
        );
        this.store.dispatch(TixEventsActions.getVenueList());
      }
      this.isSaving = false;
    } else {
      this.store.dispatch(
        eventActions.addNewEventInCompany({
          addEventObj: eventInputData,
          goToEventPage,
          addTemplateArr: this.templateArr?.map(item => {
            return {
              eventInstanceId: this.eventInstanceId,
              emailContentId: item.formGroup.controls['templateName'].value
            };
          })
        })
      );
    }
  }

  checkControlPristine(i: number): boolean | undefined {
    return (this.eventDetailForm?.get('eventPerformers') as FormArray)
      .at(i)
      ?.get('name')?.pristine;
  }

  async confirmSave() {
    const confirmText = this.isEdit
      ? `Your Event has been updated. Would you like to continue editing this Event?`
      : 'Event created successfully. Would you like to continue editing this Event?';
    const result = await this.confirmDialogService.promptConfirm({
      confirmText,
      title: this.isEdit
        ? 'Event Updated Successfully'
        : 'Event Created Successfully',
      icon: 'check',
      titleTheme: 'success',
      cancelButton: {
        theme: 'secondary',
        label: 'No'
      },
      confirmButton: {
        theme: 'primary',
        label: 'Yes'
      }
    });
    if (result) {
      this.isSaving = true;
      if (this.isEdit) {
        this.save();
        this.apollo.client.resetStore();
      } else {
        this.save(true);
        this.amplitudeService.trackEvent(
          'Button Submitted: Create Single Event'
        );
      }
    } else {
      this.eventDetailForm.markAsPristine();
      await this.save();
      const companyId = this?.route?.snapshot?.params?.companyId as string;
      // this.router.navigate([`/company/${companyId}/events`]);
    }

    return;
  }

  get socialMediasFormArray() {
    return this.socialMediasForm.get('socialMedia') as FormArray;
  }
  get mediaFilesFormArray() {
    return this.mediaFilesForm.get('media') as FormArray;
  }

  get socialMediasForm() {
    return this.eventDetailForm.controls.socialMediasForm as FormGroup;
  }
  get mediaFilesForm() {
    return this.eventDetailForm.controls.mediasForm as FormGroup;
  }

  addNewSocialMedia() {
    this.socialMediasFormArray.push(createSocialMediaFormGroup());
  }

  async confirmDeleteSocialMedia(formGroup: AbstractControl, index: number) {
    this.socialMediasFormArray.removeAt(index);
    if (this.socialMediasFormArray.length === 0) {
      this.addNewSocialMedia();
    }
  }
  async confirmDeleteMedia(formGroup: AbstractControl, index: number) {
    this.mediaFilesFormArray.removeAt(index);
    if (this.mediaFilesFormArray.length === 0) {
      this.addNewMediaFile();
    }
  }

  addNewMediaFile() {
    this.mediaFilesFormArray.push(createMediaFormGroup(null));
  }

  openUploadDialog(index: number) {
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      width: '800px',
      data: {}
    });
    dialogRef.componentInstance.imageWidth = 400;
    dialogRef.componentInstance.imageHeight = 400;

    dialogRef.afterClosed().subscribe(files => {
      if (files) {
        this.mediaFilesFormArray
          .get(index.toString())
          ?.patchValue({ location: files[0] });
        // this.eventMediaForm.markAsDirty();
      }
    });
  }

  async deleteEvent(): Promise<void> {
    this.userRolesInCompany$.subscribe(async roles => {
      console.log('deleting the event...');
      const isEventAdmin = this.isEventAdministrator(roles);
      if (isEventAdmin) return;
      const result = await this.confirmDeleteDialogService.confirmDelete({
        confirmationText: this.selectedEventData?.name
      });
      if (result) {
        this.eventsService
          .deleteEvent(this.selectedEventData.eventInstanceId)
          .then(() => {
            this.router.navigate([`/company/${this.companyId}/events`]);
          });
      } else {
        console.log(this.selectedEventData);
      }
    });
  }
}

function getselectedEventDataFromChanges(
  changes: SimpleChanges
): Maybe<TixEventInstance> {
  if (changes['selectedEventData']?.currentValue) {
    return changes['selectedEventData'].currentValue as TixEventInstance;
  }
  return null;
}

function createPerformerFormArray(performers: Array<Maybe<TixPerformer>>) {
  if (!performers || performers.length === 0)
    return new FormArray([createPerformerFormGroup()]);
  return new FormArray(
    performers.map(performer => createPerformerFormGroup(performer))
  );
}

function createPerformerFormGroup(performer?: Maybe<TixPerformer>): FormGroup {
  return new FormGroup({
    name: new FormControl(performer?.name ?? '', [Validators.required]),
    performerId: new FormControl(performer?.performerId ?? '', [
      Validators.required
    ])
  });
}

function setupSocialMediasForm(socialMedias: Array<Maybe<TixSocialMedia>>) {
  const socialMediasForm = new FormGroup({
    socialMedia: createSocialMediaArray(socialMedias)
  });

  if (socialMedias.length > 0) {
    (socialMediasForm.get('socialMedia') as FormArray).clear();
    socialMedias.forEach(socialMedia => {
      (socialMediasForm.get('socialMedia') as FormArray).push(
        createSocialMediaFormGroup(socialMedia)
      );
    });
  }

  return socialMediasForm;
}

function setupMediasForm(medias: Array<Maybe<TixMediaFile>>) {
  const mediasForm = new FormGroup({
    media: createMediaArray(medias)
  });
  if (medias.length > 0) {
    (mediasForm.get('media') as FormArray).clear();
    medias.forEach(media => {
      (mediasForm.get('media') as FormArray).push(createMediaFormGroup(media));
    });
  }

  return mediasForm;
}
