import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TixHeaderComponent } from './header/header.component';
import { TixFooterComponent } from './footer/footer.component';
import { TixCompanyLayoutComponent, TixPublicLayoutComponent } from './layouts';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import {
  HeroArrowLeft,
  HeroBell,
  HeroCalendarSolid,
  HeroChevronDown,
  HeroLocationMarkerSolid,
  HeroOfficeBuildingSolid,
  HeroPlusSm,
  HeroUsersSolid,
  HeroViewGridSolid,
  HeroCompany,
  HeroVenue,
  HeroEvent,
  HeroDocument,
  HeroStaff,
  HeroViewGrid,
  HeroSearchSolid,
  TixBreadcrumbsModule,
  TixButtonModule,
  TixIconModule,
  TixIconRegistry,
  HeroAdjustments,
  HeroCustomers,
  HeroCurrencyDollar,
  HeroDocumentReport,
  HeroTicket,
  HeroChartPie,
  HeroDocumentSearch,
  HeroChatAlt,
  HeroChevronRight,
  HeroChevronLeft,
  TixDialogModule,
  HeroFeedbackIconSolid,
  HeroUpload,
  HeroCloseXSolid,
  HeroCheckCircleSolid,
  AppLoaderModule,
  ContinuousReleaseCheckerModule
} from '@tix/shared/ui/components';
import { TixProfileMenuComponent } from './profile-menu/profile-menu.component';
import { SharedUserStateModule } from '@tix/auth/state';
import { TixEventBuyerStateModule } from '@tix/event-buyer/state';
import { DomSanitizer } from '@angular/platform-browser';
import { TixCompanySidebarComponent } from './company-sidebar/company-sidebar.component';
import { TixPublicHeaderComponent } from './public-header/public-header.component';
import { TixHomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { TixAuthModule } from '@tix/auth/module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TixBuyerLayoutComponent } from './layouts/buyer-layout/buyer-layout.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { StoreModule } from '@ngrx/store';
import { LOGS_FEATURE_KEY, logsReducer, reducer } from './state/log.reducers';
import { FeedbackService } from './services/feedback/feedback.service';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TixHeaderComponent,
    TixFooterComponent,
    TixPublicLayoutComponent,
    TixCompanyLayoutComponent,
    TixProfileMenuComponent,
    TixCompanySidebarComponent,
    TixPublicHeaderComponent,
    TixBuyerLayoutComponent,
    TixHomeLayoutComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    TixIconModule,
    TixAuthModule,
    MatSelectModule,
    SharedUserStateModule,
    TixEventBuyerStateModule,
    TixButtonModule,
    TixBreadcrumbsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    TixDialogModule,
    ReactiveFormsModule,
    AppLoaderModule,
    StoreModule.forFeature(LOGS_FEATURE_KEY, reducer),
    ContinuousReleaseCheckerModule
  ],
  exports: [TixPublicLayoutComponent, TixCompanyLayoutComponent],
  providers: [FeedbackService]
})
export class TixLayoutModule {
  constructor(
    private iconRegistry: TixIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry
      .addSvgIconConfigs([
        HeroBell,
        HeroChevronDown,
        HeroChevronRight,
        HeroChevronLeft,
        HeroCalendarSolid,
        HeroUsersSolid,
        HeroLocationMarkerSolid,
        HeroArrowLeft,
        HeroPlusSm,
        HeroChatAlt,
        HeroOfficeBuildingSolid,
        HeroViewGridSolid,
        HeroCompany,
        HeroDocumentSearch,
        HeroVenue,
        HeroEvent,
        HeroStaff,
        HeroViewGrid,
        HeroCustomers,
        HeroSearchSolid,
        HeroAdjustments,
        HeroCurrencyDollar,
        HeroDocumentReport,
        HeroDocument,
        HeroTicket,
        HeroChartPie,
        HeroFeedbackIconSolid,
        HeroUpload,
        HeroCloseXSolid,
        HeroCheckCircleSolid
      ])
      .addSvgIcon(
        'instagram-fill',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/brands/instagram-fill.svg'
        )
      )
      .addSvgIcon(
        'twitter-fill',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/brands/twitter-fill.svg'
        )
      )
      .addSvgIcon(
        'youtube-fill',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/brands/youtube-fill.svg'
        )
      );
  }
}
