import { createAction, props } from '@ngrx/store';
import { Log, NetworkCall } from './log.state';

export const addLog = createAction('[Logs] Add Log', props<{ log: Log }>());
export const clearLogs = createAction('[Logs] Clear Logs');

export const addNetworkCall = createAction(
  '[Network Calls] Add Network Call',
  props<{ networkCall: NetworkCall }>()
);
export const clearNetworkCalls = createAction(
  '[Network Calls] Clear Network Calls'
);
