import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TixUploadMediaGQL } from '@tix/data-access';
import { environment } from 'apps/purchasing-portal/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type TixAdditionalInfosInput = {
  companyName: string;
  userEmail: string;
  userId: string;
};

export type TixFeedbackInput = {
  category: TixFeedbackCategory;
  description: string;
  title: string;
};

export enum TixFeedbackCategory {
  BugReport = 'BUG_REPORT',
  FeatureRequest = 'FEATURE_REQUEST',
  SuggestedImprovement = 'SUGGESTED_IMPROVEMENT'
}

interface UploadMediaResponse {
  data?: {
    uploadMedia?: {
      url: string;
    };
  };
}

export type FeedbackAttachment = {
  filename: string;
  url: string;
};
@Injectable()
export class FeedbackService {
  private endpoint = environment.customApiUrl;
  private secretKey = environment.hasuraSecret;

  constructor(private readonly httpClient: HttpClient) {}

  submitFeedback(
    attachments: FeedbackAttachment[] | null,
    feedback: TixFeedbackInput,
    additionalInfos: TixAdditionalInfosInput,
    consoleLogs: File | null,
    networkLogs: File | null
  ) {
    const formData = new FormData();

    formData.append(
      'operations',
      JSON.stringify({
        query: `
    mutation submitFeedback(
      $attachments: [FeedbackAttachmentUrl!]
      $networkLogs: Upload
      $consoleLogs: Upload
      $feedback: FeedbackInput!
      $additionalInfos: AdditionalInfosInput!
    ) {
      submitFeedback(
        attachments: $attachments
        networkLogs: $networkLogs
        consoleLogs: $consoleLogs
        additionalInfos: $additionalInfos
        feedback: $feedback
      )
    }
  `,
        variables: {
          attachments: attachments,
          networkLogs: null,
          consoleLogs: null,
          additionalInfos: additionalInfos,
          feedback: feedback
        }
      })
    );

    if (networkLogs && consoleLogs) {
      formData.append(
        'map',
        JSON.stringify({
          '0': ['variables.networkLogs'],
          '1': ['variables.consoleLogs']
        })
      );
      formData.append(
        '0',
        networkLogs,
        `${networkLogs.name
          .replace(/\.[^/.]+$/, '')
          .replace(/[^a-zA-Z0-9]+/g, '_')}.txt`
      );
      formData.append(
        '1',
        consoleLogs,
        `${consoleLogs.name
          .replace(/\.[^/.]+$/, '')
          .replace(/[^a-zA-Z0-9]+/g, '_')}.txt`
      );
    } else {
      formData.append('map', JSON.stringify({}));
    }
    return this.httpClient.post(`${this.endpoint}/graphql`, formData, {
      reportProgress: true,
      observe: 'events',
      responseType: 'json',
      headers: {
        Accept: '*/*',
        'x-hasura-admin-secret': this.secretKey
      }
    });
  }

  uploadFeedbackVideo(video: File) {
    const operation = `mutation UploadMedia($file: Upload!) {
  uploadMedia(file: $file) {
    url
  }
}
`;

    const formData = new FormData();

    formData.append(
      'operations',
      JSON.stringify({
        query: operation,
        variables: {
          file: video
        }
      })
    );

    formData.append(
      'map',
      JSON.stringify({
        video: ['variables.file']
      })
    );

    formData.append('video', video);

    return this.httpClient
      .post(`${this.endpoint}/graphql`, formData, {
        reportProgress: true,
        observe: 'events',
        responseType: 'json',
        headers: {
          Accept: '*/*',
          'x-hasura-admin-secret': this.secretKey
        }
      })
      .pipe(
        map(response => {
          if (response.type === HttpEventType.Response) {
            if (response.status >= 200 && response.status < 300) {
              const body = response.body as UploadMediaResponse;
              if (body.data?.uploadMedia) {
                return body.data?.uploadMedia.url;
              }
            }
          }
          return null;
        })
      );
  }
}
